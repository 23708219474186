import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg,
} from "../../../utilities/images";
import DashboardPickup from './DashboardPickup';
import DashboardShipping from './DashboardShipping';
import RetailDelivery from './RetailDelivery';

const HomeRetail = () => {
    const history = useHistory()
    const [tabData, setTabData] = useState("tab1")
    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        <Link to='/dashboard'>
                            <img src={leftArrowImg} alt="" />
                        </Link>
                        <Link to="#">Maimi <i className="fas fa-chevron-down"></i></Link>
                        <p>Little Havana, Miami, FL 33130, USA</p>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabData === "tab1") ? "active" : ""} onClick={() => setTabData("tab1")}><Link to="#tab1">Delivery</Link></li>
                                <li className={(tabData === "tab2") ? "active" : ""} onClick={() => setTabData("tab2")}><Link to="#tab2">Pickup</Link></li>
                                <li className={(tabData === "tab3") ? "active" : ""} onClick={() => setTabData("tab3")}><Link to="#tab3">Reservation</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        {
                            (tabData === "tab1") ?
                                <RetailDelivery />
                                : (tabData === "tab2") ?
                                    <DashboardPickup />
                                    : <DashboardShipping />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeRetail