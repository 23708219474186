import React, { useState, useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import {
    leftArrowImg, bxsCoinStackImg, locationImg, edit2Img, DeliveryMap, WalletBlue,
    LogoIcon, OrderBlue, arrowRightSideImg, Info, veirfy2Img, Waiting
} from "../../../utilities/images";


const Placing = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }


    useEffect(() => {
        // 
    }, [])

    const renderOrderPlacedHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Order Placed</h4>
                            <img src={veirfy2Img} alt="veirfy2Img" />
                            <p>Your order placed successfully</p>
                            <h6>Order is proccessing</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn">
                            <Link to='/tracking' className="active">Track order</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderWarningHtml = () => {
        return (
            <div className="modal-body">
                <div className="modal-mainprt warning-mdlbox">
                    <div className="mdlverify-cnt cardmdl-verify">
                        <h4>Warning</h4>
                        <img src={Waiting} alt="" />
                        <p>No showing up</p>
                        <h6>No Refund!</h6>
                    </div>
                    <div className="mdlverify-btn mdllogin-btn">
                        <Link to="#" onClick={() => { setModalDetail({ show: true, flag: "OrderPlaced" }); setKey(Math.random()); }} className="active">Confirm & Place the order</Link>
                    </div>
                </div>
            </div>
        )
    }

    const renderServiceFeesHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Whats included?</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <h4>Service fees <span>$1.50</span></h4>
                        <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Taxes <span>$1.50</span></h4>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/cart' ><img src={leftArrowImg} alt="leftArrowImg" /></Link> Review & Payment </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">25 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">
                    <div className="delvaddres-boxr">
                        <h2><img src={locationImg} alt="locationImg" />Delivery Address <Link to="#"><img src={edit2Img} alt="edit2Img" /></Link></h2>
                        <img className="delv-map" src={DeliveryMap} alt="deliveryMapImg" />
                        <h4>Home</h4>
                        <p>2598 West Street <br />Holland, MI 49424</p>
                        <Link to="#" onClick={() => history.push({ pathname: '/addresses', state: "placing" })} className="aprt-btn">Apartment 359 <i className="fas fa-chevron-right"></i></Link>
                    </div>

                    <div className="delvpaymnt-box">
                        <h2><img src={WalletBlue} alt="WalletBlue" /> Balance  <Link to="#"><img src={edit2Img} alt="edit2Img" /></Link></h2>
                        <Link className="paymnt-dlbtn" to='/payment'><img src={LogoIcon} alt="" /> JBR <span>JBR 25.00</span></Link>
                    </div>

                    <div className="orderdlv-detailsbox">
                        <h2><img src={OrderBlue} alt="" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Subtotal <span>$20.56</span></li>
                                <li>Discount <span>-$5.00</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt="infoImg"
                                    //data-toggle="modal" data-target="#exampleModalCenter" 
                                    onClick={() => { setModalDetail({ show: true, flag: "WhatsIncluded" }); setKey(Math.random()); }}
                                /></small> <span>$2.00</span></li>
                                <li><Link to="#">Delivery fees</Link> <span>$4.00</span></li>
                                <li><strong>Total</strong> <strong>$19.65</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to="/terms-&-conditions">terms & conditions</Link></h6>
                    </div>

                </div>

                <div className="selected-product checkout-box">
                    <div className="selected-itembox place-ordbtn">
                        <div className="selected-leftcnt">
                            <p><strong>Place Order</strong></p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link
                                to="#"
                                onClick={() => { setModalDetail({ show: true, flag: "refund" }); setKey(Math.random()); }}
                            >
                                <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                size="md"
                child={modalDetail.flag === 'OrderPlaced' ?
                    renderOrderPlacedHtml()
                    : (modalDetail.flag === "refund") ?
                        renderWarningHtml()
                        :
                        renderServiceFeesHtml()
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Placing;