import React from "react";
import { useState } from "react";
import { useHistory } from 'react-router-dom';

const Add = (props) => {
    const history = useHistory();
    const [streets, setStreets] = useState("");
    const [apartmentOptional, setApartmentOptional] = useState("");
    const [zipCode, setZipCod] = useState("");
    const [city, setCity] = useState("");
    const [note, setNote] = useState("");

    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "streets") {
            setStreets(value);
        }
        else if (fieldName === "apartmentOptional") {
            setApartmentOptional(value);
        }
        else if (fieldName === "zipCode") {
            setZipCod(value);
        }
        else if (fieldName === "city") {
            setCity(value);
        }
        else if (fieldName === "note") {
            setNote(value);
        }
    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5>Add Address</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => { props.closeModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body address-mdlbody">
                    <form action="#" method="POST">
                        <div className="form-row">
                            <div className="col-12">
                                <div className="address-mdlitem">
                                    <label htmlFor="ad1">Address line 1</label>
                                    <input type="text" id="ad1" placeholder="Streets, building" onChange={(e) => { handleChange(e, 'streets') }} value={streets} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="address-mdlitem">
                                    <label htmlFor="ad2">Address line 2</label>
                                    <input type="text" id="ad2" placeholder="Apartment optional" onChange={(e) => { handleChange(e, 'apartmentOptional') }} value={apartmentOptional} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="address-mdlitem">
                                    <label htmlFor="ad3">Zip code</label>
                                    <input type="number" id="ad3" placeholder="12356" onChange={(e) => { handleChange(e, 'zipCode') }} value={zipCode} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="address-mdlitem">
                                    <label htmlFor="ad4">City</label>
                                    <input type="text" id="ad4" placeholder="Miami" onChange={(e) => { handleChange(e, 'city') }} value={city} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="address-mdlitem">
                                    <label htmlFor="ad5">Note</label>
                                    <input type="text" id="ad5" placeholder="Note" onChange={(e) => { handleChange(e, 'note') }} value={note} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="address-mdlitem" onClick={props?.closeModal}  >
                                    <button type="button" data-dismiss="modal" aria-label="Close">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Add;