import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ClockBlue, discountOutlineImg, dotCircleImg, emailImg, leftCircleImg, More1, Salon, searchCircleImg, Smserv1, Smserv2, Smserv3, starCircleImg, tellImg } from '../../../utilities/images'

const HomeServiceBeautySalon = () => {
    const history = useHistory()
    return (
        <div className="main-content">
            <div className="store-bannerbg hmserv-banrbg">
                <div className="left-circle">
                    <Link to='/home-service'>
                        <img src={leftCircleImg} alt="" />
                    </Link>
                </div>
                <div className="store-iconrit">
                    <Link to="#"><img src={searchCircleImg} alt="" /></Link>
                    <Link to="#"><img src={dotCircleImg} alt="" /></Link>
                </div>
            </div>
            <div className="store-banner-part">
                <div className="store-box">
                    <div className="store-item">
                        <img src={Salon} alt="" />
                    </div>

                    <div className="store-cnt hmserv-cnt">
                        <h4>TOTO HAIR <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                        <p><img src={starCircleImg} alt="" /> 4.7 (1360 ratings)</p>
                        <h6>Wella Concept Salon</h6>
                        <ul>
                            <li><img src={emailImg} alt="" /> Flowers@gmail.com</li>
                            <li><img src={tellImg} alt="" /> 612717070</li>
                            <li><img src={ClockBlue} alt="" /> OPEN till 12.00 AM</li>
                        </ul>
                    </div>
                </div>
                <div className="store-btn">
                    <Link to="#"><img src={discountOutlineImg} alt="" />
                        50% off upto $10</Link>
                </div>
            </div>

            <div className="hmserv-mainprt">
                <div className="hmserv-item" onClick={() => history.push('/select-service')} style={{ cursor: 'pointer' }}>
                    <div className="hmserv-left">
                        <img src={Smserv1} alt="" />
                    </div>
                    <div className="hmserv-right">
                        <h4>Face & Skin Care</h4>
                        <h6>Starts from <strong>USD$50.00</strong></h6>
                        <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet.</p>
                    </div>
                </div>
                <div className="hmserv-item" onClick={() => history.push('/select-service')} style={{ cursor: 'pointer' }}>
                    <div className="hmserv-left">
                        <img src={Smserv2} alt="" />
                    </div>
                    <div className="hmserv-right">
                        <h4>Hair & Styling</h4>
                        <h6>Starts from <strong>USD$50.00</strong></h6>
                        <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet.</p>
                    </div>
                </div>
                <div className="hmserv-item" onClick={() => history.push('/select-service')} style={{ cursor: 'pointer' }}>
                    <div className="hmserv-left">
                        <img src={Smserv3} alt="" />
                    </div>
                    <div className="hmserv-right">
                        <h4>Brows & Lashes</h4>
                        <h6>Starts from <strong>USD$50.00</strong></h6>
                        <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet.</p>
                    </div>
                </div>
                <div className="hmserv-item" onClick={() => history.push('/select-service')} style={{ cursor: 'pointer' }}>
                    <div className="hmserv-left">
                        <img src={Smserv1} alt="" />
                    </div>
                    <div className="hmserv-right">
                        <h4>Full Body Massage</h4>
                        <h6>Starts from <strong>USD$50.00</strong></h6>
                        <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet.</p>
                    </div>
                </div>
            </div>
            <div className="belllIcon">
                <img src={More1} alt="more" />
            </div>
        </div>
    )
}

export default HomeServiceBeautySalon