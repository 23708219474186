import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { bagImg, bxsCoinStackImg, orderOneImg, ratingImg } from "../../../utilities/images";
import { getOrderAsync, selectOrderData } from "./ordersSlice";
import { useDispatch, useSelector } from "react-redux";

const Orders = (props) => {
    // const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()
    const allorders = useSelector(selectOrderData)

    useEffect(() => {
        dispatch(getOrderAsync())
    }, [dispatch])


    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4>Orders & Reorders</h4>
                </div>
                <div className="header-right-item">
                    <button type="button"><img src={bagImg} alt="bagImg" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="order-mainprt">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm noshadow">
                            <ul className="tab-links text-left pb-4">
                                <li className="active"><Link to="#tab1">Delivery</Link></li>
                                <li><Link to="#tab2">Pickup</Link></li>
                                <li><Link to="#tab2">Shipping</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {/* <!--  <div id="tab1" className="tab active">
                                <div className="browse-tab1">

                                </div>
                            </div>

                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div>
                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div> --> */}
                        </div>
                    </div>

                    <div className="order-title">
                        <h2>Past orders</h2>
                    </div>

                    {allorders && allorders.past_orders.length > 0 &&
                        <div>
                            {allorders && allorders.past_orders.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="order-part">
                                            <div className="order-item">
                                                <img src={orderOneImg} alt="orderOneImg" />
                                                <div className="order-cnt">
                                                    <h4>{value.title}</h4>
                                                    <p>{value.description}</p>
                                                    <img src={ratingImg} alt="ratingImg" />
                                                </div>
                                            </div>
                                            <div className="order-btm">
                                                <span>{value.date_time}</span>
                                                <Link to='/past-order-view'>Select items to reorder</Link>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    }
                </div>
            </div>

        </>
    );
}

export default Orders;
