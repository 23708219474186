// import axios from "axios";
import RestClient from  '../../../utilities/RestClient'
// A mock function to mimic making an async request for data
// export function registerApi(data) {
//   var formData = new FormData();
//   formData.append("email", data.email);
//   formData.append("password", data.password);
//   formData.append("c_password", data.c_password);
//   return new Promise((resolve) =>
//     // setTimeout(() => resolve({ data: amount }), 500)
//     axios
//       .post(`${process.env.REACT_APP_API_STAGING_URL}/register`, formData)
//       .then(function (response) {
//         localStorage.setItem("user1", JSON.stringify(response.data));
//         resolve(response);
//       })
//       .catch(function (error) {
//         console.log(error);
//       })
//   );
// }

export function loginApi(data) {
  RestClient.post(`${process.env.REACT_APP_USER_SERVICES}/api/v1/users/login/`, data)
    .then(result => {
      let response = result.data;    // Response returned by server
      return response
    })
    .catch(function (error) {
      console.error(error);
    });
}

export function registerSendOtp(data) {
  RestClient.post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/send-otp`, data)
  .then(result => {
    let response = result.data;    // Response returned by server
    return response
  })
  .catch(function (error) {
    console.error(error);
  });
}
       
// export function loginApi(data) {
//   return new Promise((resolve, reject) =>
//     // setTimeout(() => resolve({ data: amount }), 500)
//     axios
//       .post(`${process.env.REACT_APP_API_STAGING_URL}/login`, data)
//       .then(function (response) {
//         if (response?.data?.data?.user_id !== "") {
//           localStorage.setItem("user", JSON.stringify(response.data));
//         } else {
//           console.error(response);
//         }
//         resolve(response);
//       })
//       .catch(function (error) {
//         console.error(error);
//       })
//   );
// }

