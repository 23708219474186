import React, { useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import {
    offerCrsImg12, starCircleImg, clockImg,
    brandOneImg, trackImg, sliderOneImg, brand3Img, brand6Img, bannerSld2Img, arrowRightSideImg, sendImg,
    deliveryTruckImg, dotDarkImg, deliveryParcelImg,
    delv1Img
} from "../../../utilities/images";
import { useSelector, useDispatch } from "react-redux";
import { getBrandAsync, getProductAsync, getOfferAsync, selectBrandData, selectProductData, selectOfferData } from "./dashboardSlice"
import discountData from "../../apis/discount.json"
import { Link, useHistory } from "react-router-dom";

const DashboardDelivery = () => {

    const dispatch = useDispatch();
    const history = useHistory()
    const brandData = useSelector(selectBrandData);
    const productData = useSelector(selectProductData);
    const offerData = useSelector(selectOfferData);

    let megaOffer = [1, 2, 3, 4]

    // console.log(brandData, "brand Data")
    // console.log(productData, "productData Data")
    // console.log(offerData, "offerData Data")

    useEffect(() => {
        dispatch(getBrandAsync())
        dispatch(getProductAsync())
        dispatch(getOfferAsync())
    }, [dispatch])
    return (
        <div className="home-tab1prt">
            <div className="search-barhm">
                <form action="#" method="POST">
                    <input type="search" placeholder="Search here" onFocus={() => history.push('search-ints')} />
                </form>
            </div>
            <OwlCarousel items={1}
                className="owl-theme"
                nav={false}
                dots={true}
                autoplay={true}
                margin={1} >
                {
                    megaOffer && megaOffer.map((val, index) => {
                        return (
                            <div className="carousel-inner" key={index}>
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={sliderOneImg} alt="First slide" />
                                </div>
                            </div>
                        )
                    })
                }
            </OwlCarousel>

            {offerData?.length > 0 ?
                <div className="offer-areahm">
                    <div className="offer-title">
                        <h2>Offers</h2>
                    </div>
                    <div className='container-fluid' >
                        <OwlCarousel items={2.5}
                            className="owl-theme"
                            nav={false}
                            dots={false}
                            autoplay={true}
                            margin={0} >
                            {offerData?.map((offerData, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="carousel-cell">
                                            <div className="offer-crslpart">
                                                <div className="offer-crslitem">
                                                    <img src={offerCrsImg12} alt="offerCrsImg1" />
                                                    <div className="offer-crslinner">
                                                        <h2>{offerData.off}</h2>
                                                        <p>Upto {offerData.upto} <span>AD</span></p>
                                                    </div>
                                                </div>
                                                <div className="offer-crslcnt">
                                                    <h4>{offerData.title}</h4>
                                                    <ul>
                                                        <li><img src={starCircleImg} alt="starCircleImg" />{offerData.rating}</li>
                                                        <li><img src={clockImg} alt="clockImg" /> {offerData.time}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </OwlCarousel>
                    </div>
                </div>
                : ""}

            {brandData?.top_cigar?.length > 0 ?
                <div className="topbrand-area">
                    <div className="browse-part brand-tprt">
                        <div className="browse-upper">
                            <h2>Top Cigar Brands</h2>
                            <Link to="#">View all</Link>
                        </div>
                        {brandData?.top_cigar.map((value, index) => {
                            let itemsLength = brandData?.top_cigar.length;
                            return (
                                <React.Fragment key={index}>
                                    <div className={itemsLength === (index + 1) ? 'browse-item border-0' : 'browse-item'}>
                                        <div className="browse-left">
                                            <img src={brandOneImg} alt="brandOneImg" />
                                            <span>{value?.id}</span>
                                            <div className="browse-lftcnt">
                                                <h4>{value?.title}</h4>
                                                <p>{value?.listed}</p>
                                            </div>
                                        </div>
                                        <div className="browse-right text-center">
                                            <Link to="#">View</Link>
                                            <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )

                        })
                        }
                    </div>
                </div>
                : ""}
            {brandData?.top_cigrates?.length > 0 &&
                <div className="topbrand-area">
                    <div className="browse-part brand-tprt">
                        <div className="browse-upper">
                            <h2>Top Cigarate Brands</h2>
                            <Link to="#">View all</Link>
                        </div>
                        {brandData?.top_cigrates.map((value, index) => {
                            let itemsLength = brandData?.top_cigar.length;
                            return (
                                <React.Fragment key={index}>
                                    <div className={itemsLength === (index + 1) ? 'browse-item border-0' : 'browse-item'}>
                                        <div className="browse-left">
                                            <img src={brand3Img} alt="brand3Img" />
                                            <span>{value?.id}</span>
                                            <div className="browse-lftcnt">
                                                <h4>{value?.title}</h4>
                                                <p>{value?.listed}</p>
                                            </div>
                                        </div>
                                        <div className="browse-right text-center">
                                            <Link to="#">View</Link>
                                            <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </div>
            }
            {brandData?.top_vape?.length > 0 &&
                <div className="topbrand-area">
                    <div className="browse-part brand-tprt">
                        <div className="browse-upper">
                            <h2>Top Vape Brands</h2>
                            <Link to="#">View all</Link>
                        </div>
                        {brandData?.top_vape.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="browse-item">
                                        <div className="browse-left">
                                            <img src={brand6Img} alt="brand6Img" />
                                            <span>{value?.id}</span>
                                            <div className="browse-lftcnt">
                                                <h4>{value?.title}</h4>
                                                <p>{value?.listed}</p>
                                            </div>
                                        </div>
                                        <div className="browse-right text-center">
                                            <Link to="#">View</Link>
                                            <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </div>
            }

            <div className='container-fluid' >
                <OwlCarousel items={1.2}
                    className="owl-theme"
                    nav={false}
                    dots={false}
                    autoplay={true}
                    margin={8} >
                    {discountData && discountData.map((data, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active banner2-item">
                                            <img className="d-block w-100" src={bannerSld2Img} alt="bannerSld2Img" />
                                            <Link to="#">Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </OwlCarousel>
            </div>

            <div className="delivery-parthm">
                <div className="delivery-upperhm">
                    <ul>
                        <li><Link to="#"><img src={sendImg} alt="sendImg" /> Near Me</Link></li>
                        <li><Link to="#"><img src={deliveryTruckImg} alt="deliveryTruckImg" /> Free Delivery</Link></li>
                        <li><Link to="#"><img src={dotDarkImg} alt="dotDarkImg" /></Link></li>
                    </ul>
                </div>
                {productData.length > 0 &&
                    <div className="topbrand-area">
                        <div className="browse-part brand-tprt">
                            <div className="browse-upper">
                                <h2>Top Cigarate Brands</h2>
                                <Link to="#">View all</Link>
                            </div>
                            {productData?.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="delivery-sechm">
                                            <img className="delv-prd" src={delv1Img} alt="delv1Img" />
                                            <h4>{value.title}</h4>
                                            <ul>
                                                <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                                                <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                                                <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value.upto}</li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DashboardDelivery