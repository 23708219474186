import React from "react";
import { Link, useHistory } from 'react-router-dom';
import {leftArrowImg,editImg, bxsCoinStackImg,profileImageImg,user2Img, emailImg, tellImg, verifyImg, calender2Img } from "../../../utilities/images";

const Profile = (props) => {
    const history = useHistory();

  return (
        <> 
          <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Profile</h4>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => {history.push('/edit-profile')}}><img src={editImg}alt="editImg" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="profile-imageprt">
                    <Link to="#">
                        <img src={profileImageImg} alt="profileImageImg" />
                    </Link>
                </div>

                <div className="profile-describe">
                    <form action="#" method="POST">
                        <div className="profile-info">
                            <label htmlFor="">Name</label>
                            <p><img src={user2Img} alt="user2Img" /> Michael R. Flowers</p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Email address</label>
                            <p><img src={emailImg} alt="emailImg" /> Flowers@gmail.com</p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Phone number</label>
                            <p><img src={tellImg} alt="tellImg" /> 612717070 <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">SSN</label>
                            <p><img src={calender2Img} alt="calender2Img" /> -------5698 <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Date of Birth</label>
                            <p><img src={calender2Img} alt="calender2Img" /> 01/02/1988</p>
                        </div>
                        {/* <!-- <div className="profile-info">
                            <label for="">Password</label>
                            <div className="password-feild">
                                <input id="pass_log_id" type="password" name="pass" value="*********">
                                <span toggle="#password-field" className="fa fa-fw fa-eye-slash field_icon toggle-password"></span>
                            </div>

                            <a href="#">Change Password</a>
                        </div> --> */}
                    </form>
                </div>

               {/* <!--  <div className="profile-connectbtn">
                    <a href="#">
                        <img src="images/facebook.png" alt="">
                        <h4>Facebook <span>Connected</span></h4>
                        <i className="fas fa-times"></i>
                    </a>
                    <a href="#">
                        <img src="images/google.png" alt="">
                        <h4>Google <span className="red">Connect</span></h4>
                    </a>
                </div> --> */}
            </div>
            
        </>
     );
    }  
    
   
   export default Profile;