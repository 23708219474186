import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, review1Img, ratingDarkImg, smokeImg, review2Img, review3Img, ratingImg } from "../../../utilities/images";

const ProductReview = (props) => {
    const [tabData, setTabData] = useState((props?.location?.state === "review") ? 'tab2' : 'tab1')
    return (
        <>
            <div className="order-heading">
                <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="review-uptbtnp">
                        <Link to="#">Earn money providing product review</Link>
                    </div>

                    <div className="product-review-tab">
                        <div className="tabs">
                            <div className="tab-upper tab-upperhm noshadow">
                                <ul className="tab-links text-left pt-4">
                                    <li className={tabData === 'tab1' ? "active" : ""} onClick={() => setTabData('tab1')}><Link to="#tab1">Available for reviews</Link></li>
                                    <li className={tabData === 'tab2' ? "active" : ""} onClick={() => setTabData('tab2')}><Link to="#tab2">Past reviews</Link></li>
                                </ul>
                            </div>

                            <div>
                                {tabData === 'tab1' ?
                                    <div id="tab1" className={tabData === 'tab1' ? "active" : ""}>
                                        <div className="review-tab1">
                                            <div className="review-itemp">
                                                <div className="review-leftp">
                                                    <img src={review1Img} alt="review1Img" />
                                                    <p>14 Jun, 21:33</p>
                                                </div>
                                                <div className="review-cntp">
                                                    <h2>Marlboro Touch</h2>
                                                    <p><img src={smokeImg} alt="smokeImg" /> Miami smoke</p>
                                                    <img src={ratingDarkImg} alt="ratingDarkImg" />
                                                    <h6>Provide review</h6>
                                                </div>
                                                <div className="review-rightp">
                                                    <Link to='/product-review-available-two'><i className="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                            <div className="review-itemp">
                                                <div className="review-leftp">
                                                    <img src={review1Img} alt="review1Img" />
                                                    <p>14 Jun, 21:33</p>
                                                </div>
                                                <div className="review-cntp">
                                                    <h2>Marlboro Touch</h2>
                                                    <p><img src={smokeImg} alt="smokeImg" /> Miami smoke</p>
                                                    <img src={ratingDarkImg} alt="ratingDarkImg" />
                                                    <h6>Provide review</h6>
                                                </div>
                                                <div className="review-rightp">
                                                    <Link to='/product-review-available-two'><i className="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                            <div className="review-itemp">
                                                <div className="review-leftp">
                                                    <img src={review2Img} alt="review2Img" />
                                                    <p>14 Jun, 21:33</p>
                                                </div>
                                                <div className="review-cntp">
                                                    <h2>Marlboro Red</h2>
                                                    <p><img src={smokeImg} alt="smokeImg" /> Miami smoke</p>
                                                    <img src={ratingDarkImg} alt="ratingDarkImg" />
                                                    <h6>Provide review</h6>
                                                </div>
                                                <div className="review-rightp">
                                                    <Link to='/product-review-available-two'><i className="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div id="tab2" className={tabData === 'tab2' ? "active" : ""}>
                                        <div className="review-tab2">
                                            <div className="review-itemp">
                                                <div className="review-leftp">
                                                    <img src={review3Img} alt="review3Img" />
                                                    <p>14 Jun, 21:33</p>
                                                </div>
                                                <div className="review-cntp">
                                                    <h2>Marlboro Red</h2>
                                                    <p><img src={smokeImg} alt="smokeImg" /> Miami smoke</p>
                                                    <img src={ratingImg} alt="ratingImg" />
                                                    <h6><Link to='/product-review-edit'>Edit Review</Link></h6>
                                                </div>
                                                <div className="review-rightp">
                                                    <Link to='/product-review-available-two'><i className="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductReview;
