import React from "react";
import { Link, useHistory } from 'react-router-dom';
import {leftArrowImg,emilDarkImg,userIconDarkImg,bagImg,bxsCoinStackImg, infoDarkImg, dotDarkImg} from "../../../utilities/images";

const HelpCenter = (props) => {
    const history = useHistory();

  return (
        <>
          <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link onClick={() => {history.push('/more')}}><img src={leftArrowImg} alt="leftArrowImg" /></Link> How can we help you?</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="help-part">
                    <ul>
                        <li>
                            <Link to='/my-support-request'><img src={emilDarkImg} alt="eamilDarkImg" /> My support request <span>Pending</span> <i className="fas fa-chevron-right m-0"></i></Link>
                        </li>
                        <li>
                            <Link to='/help-center-two'><img src={bagImg} alt="bagImg" />Past orders <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to='/my-account'><img src={userIconDarkImg} alt="userIconDarkImg" /> My account<i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to='/report-an-issue'><img src={infoDarkImg} alt="infoDarkImg" /> Report other issue<i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to='/faq'><img src={dotDarkImg} alt="dotDarkImg" /> FAQ<i className="fas fa-chevron-right"></i></Link>
                        </li>
                    </ul>
                </div>                                       
            </div>

        </>
      );
    }  
    

export default HelpCenter;