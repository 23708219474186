import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
  leftArrowImg, bellImg, bagImg, bxsCoinStackImg, ProductSt8, RetailItem1, RetailItem2, RetailItem3, RetailItem4, RetailItem5, RetailItem6, RetailItem7, HomeServiceBanner, HmServ1, HmServ2, HmServ3, HmServ4, starCircleImg, clockImg, Trending3
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { selectRetailData, getRetailDataAsync } from "./dashboardSlice"

const HomeService = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const retailData = useSelector(selectRetailData);

  let megaOffer = [1, 2, 3, 4]

  useEffect(() => {
    dispatch(getRetailDataAsync())
  }, [dispatch])
  return (
    <>
      <div className="header-part browse-addprdcard-header">
        <div className="header-left-item">
          <div className="header-left">
            <Link to='/dashboard'>
              <img src={leftArrowImg} alt="" />
            </Link>
            <Link to="#">Maimi <i className="fas fa-chevron-down"></i></Link>
            <p>Little Havana, Miami, FL 33130, USA</p>
          </div>
        </div>
        <div className="header-right-item">
          <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
          <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="" /></button>
          <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
        </div>
      </div>
      <div className="main-content">
        <div className="home-tab">
          <div className="search-barhm">
            <form action="#" method="POST">
              <input type="search" placeholder="Search here" />
            </form>
          </div>
          {/* categories */}
          <div className="retail-part retail-part2">
            <div className="retail-item">
              <span>
                <img src={RetailItem1} alt="" />
              </span>
              <p>AC Repare <br /> Services</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem2} alt="" />
              </span>
              <p>Beauty & <br /> Salon</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem3} alt="" />
              </span>
              <p>Cleaning & <br /> Pest Control</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem4} alt="" />
              </span>
              <p>Car Care <br /> Services</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem5} alt="" />
              </span>
              <p>Painting & <br /> Renovation</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem6} alt="" />
              </span>
              <p>Electric & <br /> Plumbing</p>
            </div>
            <div className="retail-item">
              <span>
                <img src={RetailItem7} alt="" />
              </span>
              <p>Moving & <br /> Shifting </p>
            </div>
            <div className="retail-item">
              <Link to="#">
                <img src={ProductSt8} alt="" />
              </Link>
              <p>More</p>
            </div>
          </div>
          {/* mega slider  */}
          <div id="bannerSlider">
            <OwlCarousel
              items={1}
              className="owl-theme"
              loop={true}
              nav={false}
              dots={false}
              autoplay={true}
              autoplayTimeout={1500}
              slidespeed={100}
              dotsdata="false"
            >
              {
                megaOffer && megaOffer.map((val, index) => {
                  return (
                    <div className="carousel-cell" key={index}>
                      <div>
                        <img className="d-block w-100" src={HomeServiceBanner} alt="First slide" />
                      </div>
                      <div className="owl-dots dotstab d-block">
                        {megaOffer?.map((val, index1) => {
                          return (
                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
              }
            </OwlCarousel>
          </div>

          <div className="retail-mainprt">
            <div className="row">
              <div className="col-6">
                <div className="retail-product">
                  <img src={HmServ1} alt="" />
                  <span>Hair Cut</span>
                </div>
              </div>
              <div className="col-6">
                <div className="retail-product">
                  <img src={HmServ2} alt="" />
                  <span className="nails">Designs and Nail Art</span>
                </div>
              </div>
              <div className="col-6">
                <div className="retail-product">
                  <img src={HmServ3} alt="" />
                  <span>Spa</span>
                </div>
              </div>
              <div className="col-6">
                <div className="retail-product rtprod-lst">
                  <img src={HmServ4} alt="" />
                  <span>Car Wash</span>
                </div>
              </div>
            </div>
          </div>

          {retailData.length > 0 ?
            <div className="delivery-parthm">
              <div className="trending-title pt-5">
                <h2>Trending</h2>
              </div>
              {retailData?.map((value, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push('/home-service-beauty-salon')}>
                      <img className="delv-prd" src={Trending3} alt="" />
                      <h4>Top Shoes</h4>
                      <ul>
                        <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                        <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                        <li><img src={RetailItem2} alt="category" />{value.category}</li>
                      </ul>
                    </div>
                  </React.Fragment>
                )

              })
              }
            </div> : ""
          }

        </div></div>
    </>
  )
}

export default HomeService