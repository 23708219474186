import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, clockEditOutlineImg } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";

const TimeSlot = () => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={()=>history.goBack()}><img src={leftArrowImg} alt="" /></Link> Change time window</h4>
                </div>
            </div>

            <div className="main-content">
                <div className="delivery-typearea">
                    <form action="#" method="POST">
                        <div className="change-timebtn">
                            <Link to="#">Change with 00:56:16</Link>
                        </div>
                        <div className="delivery-typeablw">
                            <h4>Available 1 hour windows</h4>
                            <div className="delivery-time">
                                <span className="inactive">9:00a-10:00a</span>
                                <span>11:00a-12:00p</span>
                                <span>12:00p-1:00p</span>
                                <span className="inactive">1:00p-2:00p</span>
                                <span>2:00p-3:00p</span>
                                <span>3:00p-4:00p</span>
                                <span>4:00p-5:00p</span>
                                <span>5:00p-6:00p</span>
                                <span>6:00p-7:00p</span>
                                <span>7:00p-8:00p</span>
                                <span className="active">8:00p-9:00p</span>
                                <span>9:00p-10:00p</span>
                                <span>10:00p-11:00p</span>
                                <span>11:00p-12:00a</span>
                                <span>11:00p-12:00a</span>
                                <span>12:00a-1:00a</span>
                            </div>
                            <p>** You can change time slot before 1:00pm EST | Charge applicable</p>
                        </div>
                    </form>
                    <div className="delivery-typebtn">
                        <Link to="#" onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }}>Change</Link>
                    </div>
                </div>
            </div>
            <CustomModal
                key = {key}
                show = {modalDetail.show}
                backdrop = "static"
                showCloseBtn= {true}
                isRightSideModal={true}
                child = {
                    <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-mainprt warning-mdlbox">
                            <div className="mdlverify-cnt cardmdl-verify time-changmdl">
                                <h4>Time Change</h4>
                                <img src={clockEditOutlineImg} alt="clockEditOutlineImg" />
                                <p>Your are changing delivey time <br/> 03:00p-4:00p to <span>9:00p-10:00p</span></p>
                                <h6>Charge JBR 5.00</h6>
                            </div>
                            <div className="mdlverify-btn mdllogin-btn">
                                <Link to="#" onClick={() => {history.push('/order-processing')}} className="active">Proceed now</Link>
                            </div>
                        </div>
                    </div>
                </div>
                        
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default TimeSlot