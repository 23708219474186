import React, { useEffect } from 'react'
import {
    Food1, Food2, Food3, Food4, Food5, Food6, Food7, FoodItem1, FoodItem2, FoodItem3, FoodItem4, FoodSlider, ProductSt8,
    starCircleImg, clockImg, deliveryParcelImg, TrendingFood1
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { getFoodDataAsync, selectFoodData } from "./dashboardSlice"
import { Link, useHistory } from 'react-router-dom';

const FoodDeliveryTab = () => {

    const dispatch = useDispatch();
    const history = useHistory()
    const foodData = useSelector(selectFoodData);

    let megaOffer = [1, 2, 3, 4]

    useEffect(() => {
        dispatch(getFoodDataAsync())
    }, [dispatch])
    return (
        <div id="tab1" className="active">
            <div className="home-tab1prt">
                <div className="search-barhm">
                    <form action="#" method="POST">
                        <input type="search" placeholder="Search here" />
                    </form>
                </div>

                <div className="retail-part retail-part2">
                    <div className="retail-item">
                        <span>
                            <img src={Food1} alt="" />
                        </span>
                        <p>Fast food</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food2} alt="" />
                        </span>
                        <p>Pizza</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food3} alt="" />
                        </span>
                        <p>Coffee</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food4} alt="" />
                        </span>
                        <p>Indian food</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food5} alt="" />
                        </span>
                        <p>Soup</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food6} alt="" />
                        </span>
                        <p>Salad</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food7} alt="" />
                        </span>
                        <p>Drinks </p>
                    </div>
                    <div className="retail-item">
                        <Link to="#">
                            <img src={ProductSt8} alt="" />
                        </Link>
                        <p>More</p>
                    </div>
                </div>

                <div id="bannerSlider">
                    <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={1500}
                        slidespeed={100}
                        dotsdata="false"
                        margin={1}
                    >
                        {
                            megaOffer && megaOffer.map((val, index) => {
                                return (
                                    <div className="carousel-cell" key={index}>
                                        <img src={FoodSlider} alt="" />
                                        <div className="owl-dots dotstab d-block">
                                            {megaOffer?.map((val, index1) => {
                                                return (
                                                    <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

                <div className="retail-mainprt">
                    <div className="row">
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={FoodItem1} alt="" />
                                <span>Pizza</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={FoodItem2} alt="" />
                                <span>Burger</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={FoodItem3} alt="" />
                                <span>Sushi</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product rtprod-lst">
                                <img src={FoodItem4} alt="" />
                                <span>Sea food</span>
                            </div>
                        </div>
                    </div>
                </div>

                {foodData.length > 0 &&
                    <div className="delivery-parthm">
                        <div className="trending-title pt-5">
                            <h2>Trending</h2>
                        </div>
                        {foodData?.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push('/home-restaurent')}>
                                        <img className="delv-prd" src={TrendingFood1} alt="" />
                                        <h4>{value.title}</h4>
                                        <ul>
                                            <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                                            <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                                            <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value.upto}</li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default FoodDeliveryTab