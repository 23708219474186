import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, SearchImg, bxsCoinStackImg, tob1Img, tob2Img, tob3Img, tob4Img, tob5Img, tob6Img, tob7Img, tob8Img, tob9Img, tob10Img, tob11Img, tob12Img, tob13Img, tob14Img} from "../../../utilities/images";

const BrowseNext = (props) => {
    const history = useHistory();
    
    useEffect(() => {

    }, [])

    return (
        <>
          <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => {history.goBack()}}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Tobacco</h4>
                </div>
                <div className="header-right-item">
                    <button>
                        <img src={SearchImg} alt="SearchImg" />
                    </button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="tobacco-mainitem">
                    <ul>
                        <li><Link to="#"><img src={tob1Img} alt="tob1Img" /> Accessories <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob2Img} alt="tob2Img" /> Batteries & Chargers <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#" onClick={() => {history.push('/product-add-to-cart')}}><img src={tob3Img} alt="tob3Img" /> Cigarettes <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob4Img} alt="tob4Img" /> Cigarillos <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob5Img} alt="tob5Img" /> Consumer Electronics <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob6Img} alt="tob6Img" /> Dab <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob7Img} alt="tob7Img" /> Glass <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob8Img} alt="tob8Img" /> Grinders <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob9Img} alt="tob9Img" /> Odor Control <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob10Img} alt="tob10Img" /> Pipes <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob11Img} alt="tob11Img" /> Pods System <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob12Img} alt="tob12Img" /> Rolling Papers <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob13Img} alt="tob13Img" /> Tobacco <i className="fas fa-chevron-right"></i></Link></li>
                        <li><Link to="#"><img src={tob14Img} alt="tob14Img" /> Torches & Lighters <i className="fas fa-chevron-right"></i></Link></li>
                    </ul>
                </div>
            </div>
            
        </>
    );
}

export default BrowseNext;