import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//import { ForgotPassword } from '..';
import { toast } from 'react-toastify';
import logo from "../../public/images/logo.svg";
import LockOneImg from "../../public/images/lock-1.png";
import PhoneInput from 'react-phone-input-2'

const ForgotPassword = (props) => {
    const history = useHistory();
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");
    const [loading, setLoading] = useState(false);

    const onChangePhoneNumber = (value, data, event, formattedValue) => {
      let phoneCode = data.dialCode;
      let phoneNumber = value.slice(data.dialCode.length);
      setPhoneNo(phoneNumber);
      SetPhoneCode(phoneCode);
    }

    const sendOtp = () => {
        if (!phoneNo) {
            toast.error("Please enter phone number");
            return;
        }
        else if (phoneNo && phoneNo.length <= 4) {
            toast.error("Phone number length should be of 5 to 15 digits");
            return;
        }
        else if(phoneNo && !phoneCode){
            toast.error("Please select your phone code");
            return;
        }
        history.push('/otp-verification');
    }
    
    useEffect(() => {
        // props.logout();
    }, []);

    return (
        <>
            <div className="logo-part">
                <a onClick={() => {history.push('/')}}>
                    <img src={logo} alt="" />
                </a>
            </div>

            <div className="lock-part">
                <img src={LockOneImg} alt="" />
            </div>
            <div className="phone-numbpart">
                <h2>Enter your Phone number</h2>
                <p>We’ll send you OTP to set your new PIN</p>
                <div className="country-plugin">
                    <form action="#" name="POST">
                        <div id="result">
                                <PhoneInput
                                    country="us"
                                    // value={phoneNo}
                                    placeholder="Phone no."
                                    onChange={(value, data, event, formattedValue) => {onChangePhoneNumber(value, data, event, formattedValue)}}
                                />
                            <span id="valid-msg" className="hide">✓ Valid</span>
                            <span id="error-msg" className="hide"></span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="bottom-part">
                <a className="bottom-btn" onClick={() => {sendOtp()}}>Send</a>
            </div>
        </>
    )

}

export default ForgotPassword;