import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";

const Coupons = (props) => {
    // const history = useHistory();

    const [couponCode, setCouponCode] = useState("");
    const [tabData, setTabData] = useState("tab1")

    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "couponCode") {
            setCouponCode(value);
        }
    }

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Coupons</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="coupon-part">
                    <form action="#" method="POST">
                        <input type="text" placeholder="Coupon code" onChange={(e) => { handleChange(e, 'couponCode') }} value={couponCode} />
                        <button type="button">Add Coupon</button>
                    </form>
                </div>

                <div className="coupon-tab">
                    <div>
                        <div className="tab-upper">
                            <ul className="tab-links tab-menu">
                                <li className={tabData === 'tab1' ? "active" : ""} onClick={() => setTabData('tab1')}><Link to="#">Current</Link></li>
                                <li className={tabData === 'tab2' ? "active" : ""} onClick={() => setTabData('tab2')}><Link to="#">Past</Link></li>
                            </ul>
                        </div>

                        <div>
                            {(tabData === 'tab1') ?
                                <div id="tab1" className={tabData === 'tab1' ? "tab active" : ""}>
                                    <div className="coupon-tab1">
                                        <div className="coupon-box">
                                            <h2>Welcome coupon <span>USD $2.00</span></h2>
                                            <p>jbvrgcu01</p>
                                            <h4><span>USD $30 <small>minimum</small></span> <span><small>Valid until</small> Sep 14, 200</span></h4>
                                            <div className="line"></div>
                                        </div>
                                        <div className="coupon-btm">
                                            <span>Cigar</span>
                                            <Link to="/terms-&-conditions">T&C</Link>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div id="tab2" className={tabData === 'tab2' ? "tab active" : ""}>
                                    <div className="coupon-tab1">
                                        <div className="coupon-box coupon-box2">
                                            <h2>Not Active Coupon <span>USD $2.00</span></h2>
                                            <p>jbvrgcu01</p>
                                            <h4><span>USD $30 <small>minimum</small></span> <span><small>Valid until</small> Sep 14, 200</span></h4>
                                            <div className="line"></div>
                                        </div>
                                        <div className="coupon-btm coupon-btm2">
                                            <Link to="/terms-&-conditions">T&C</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Coupons;