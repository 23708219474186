import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, Like } from "../../../utilities/images";

const FeedbackThankYou = () => {
    const history = useHistory()
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => history.push('/feedback')}><img src={leftArrowImg} alt="" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content tank-feed">
                <div className="feedback-thankprt">
                    <img src={Like} alt="" />
                    <h4>Thank you</h4>
                    <p>your feedback is turly  appricalbe</p>
                    <Link to="#" onClick={() => history.push('/dashboard')}>Back home</Link>
                </div>
                <div className="feedback-form submit-tnkfed">
                    <form action="#" method="POST">
                        <button onClick={() => history.push('/dashboard')}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FeedbackThankYou