import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAllBrowse,
} from "./browserApi";

const initialState = {
    category: null,
    status: "idle",
};

export const getBrowseAsync = createAsyncThunk("browse/data", async (data) => {
    const response = await getAllBrowse();
    return response;
});

export const browseSlice = createSlice({
    name: "browse",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getBrowseAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getBrowseAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.category = action.payload;
            })
    },
});

export const selectBrowseData = (state) => state.browse.category;

export default browseSlice.reducer;