import React, { useState, useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { chartImg, Cross, reloadImg, searchType1Img, searchType2Img, topPrd1Img, topPrd2Img, topPrd3Img } from "../../../utilities/images";

const SearchInts = (props) => {
    // const history = useHistory();
    const [searchInput, setSearchInput] = useState('');
    // const [searchVisible, setSearchVisible] = useState(false)

    const onChangeSearchInput = (searchValue, isClearSearch = false) => {
        setSearchInput(searchValue);
    }
    // console.log(searchInput)

    useEffect(() => {
        // 
    }, [])

    return (
        <>
            <div className="main-content">
                <div className="search-intsarea">
                    <div className="search-bar">
                        <form action="#" method="POST">
                            <input className="search-input" type="search" placeholder="Search here" onChange={(e) => onChangeSearchInput(e.target.value)} />
                        </form>

                        <div className="search-type">
                            <form action="#" method="POST">
                                <input type="search" onChange={(e) => onChangeSearchInput(e.target.value)} />
                                <button type="button"><img src={Cross} alt="Cross" /></button>
                            </form>
                            {searchInput.length > 0 ?
                                <ul>
                                    <li>
                                        <Link to="/search-result">
                                            <img src={searchType1Img} alt="searchType1Img" />
                                            <div className="search-typinner">
                                                <h4>Maronii Tobacco LLC</h4>
                                                <p>Retail shop</p>
                                            </div>
                                            <span className="sponsore">Sponsored</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType1Img} alt="searchType1Img" />
                                            <div className="search-typinner">
                                                <h4>Maronii Tobacco LLC</h4>
                                                <p>Retail shop</p>
                                            </div>
                                            <span className="sponsore">Sponsored</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType1Img} alt="searchType1Img" />
                                            <div className="search-typinner">
                                                <h4>Maronii Tobacco LLC</h4>
                                                <p>Retail shop</p>
                                            </div>
                                            <span>0.8 km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4 >Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img src={searchType2Img} alt="searchType2Img" />
                                            <div className="search-typinner">
                                                <h4>Marlboro</h4>
                                                <p>Product</p>
                                            </div>
                                            <span>2.1 Km</span>
                                        </Link>
                                    </li>
                                </ul>
                                : ""}
                        </div>
                    </div>

                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links text-left pt-0 pl-3">
                                <li className="active"><Link to="#tab1">Products</Link></li>
                                <li><Link to="#tab2">Retails</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {/* <!-- <div id="tab1" className="tab active">
                                <div className="browse-tab1">
                                </div>
                            </div>
                            <div id="tab2" className="tab">
                                <div className="browse-tab2">
                                </div>
                            </div> --> */}
                        </div>
                    </div>

                    <div className="recent-search">
                        <h4>Recent Searches</h4>
                        <Link to='/search-result'><img src={reloadImg} alt="reloadImg" /> Marlboro</Link>
                        <Link to="#"><img src={reloadImg} alt="reloadImg" /> Marlboro gold</Link>
                    </div>

                    <div className="recent-search trending-search">
                        <h4>Trending Searches</h4>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> Marlboro</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> Dunhill</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> Marlboro gold</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> Vape</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> Marlboro</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> winstone</Link>
                        <Link to="#"><img src={chartImg} alt="chartImg" /> LM</Link>
                    </div>

                    <div className="top-product">
                        <h2>Top Products</h2>
                        <ul>
                            <li><Link to="#"><img src={topPrd1Img} alt="topPrd1Img" /> Cigar</Link></li>
                            <li><Link to="#"><img src={topPrd2Img} alt="topPrd2Img" /> Cigaratte</Link></li>
                            <li><Link to="#"><img src={topPrd3Img} alt="topPrd3Img" /> Vape</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchInts;