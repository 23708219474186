import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getNotifications,
} from "./notificationApi";

const initialState = {
    notification: [],
    status: "idle"
};

export const getNotificationAsync = createAsyncThunk("notification/data", async (data) => {
    const response = await getNotifications();
    return response;
});

export const notificationSlice = createSlice({
    name: "notification",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getNotificationAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getNotificationAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.notification = action.payload;
            })
    },
});

export const selectNotificationData = (state) => state.notification.notification;

export default notificationSlice.reducer;