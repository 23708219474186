import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";

const ReferAndEarnTwo = (props) => {

    useEffect(() => {
        //
    }, [])

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to='/refer-and-earn'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Referral history</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="refer-history">
                    <div className="refer-history-heading">
                        <span>Date</span>
                        <span>Reffered</span>
                        <span>Rewards</span>
                    </div>
                    <ul>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Sariffe</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Mick260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ReferAndEarnTwo;