import React from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";

const MyAccount = (props) => {

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> My account</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0  <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="help-part">
                    <ul>
                        <li>
                            <Link to='/my-account-two'>Update account information <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to="#">Forgot my password <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to="#">SMS verification not received <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to='/home-support'>Delete my account <i className="fas fa-chevron-right"></i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default MyAccount;