import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../public/images/brand-logo.png";
import PinField from "react-pin-field"

const Login = (props) => {
    const history = useHistory();
    const [pin, setPin] = useState("");
    const [loading, setLoading] = useState(false);

    const onComplete = (code) => {
        setPin(code);
    }

    const handleOnSubmit = () => {
        if (!pin || pin.length < 4) {
            toast.error("Please enter your PIN");
            return false
        }
        history.push('/dashboard')
    }
    useEffect(() => {
        // props.logout();
    }, []);

    return (
        <>
            <div className="logo-login">
                <a onClick={(e) => { e.preventDefault() }}>
                    <img src={logo} alt="logo" />
                </a>
            </div>
            <div className="login-main">
                <p>Enter your PIN for login</p>
                {/* <div className="pin-box text-center" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
                <div className="pin-box text-center" >
                    <PinField className='input_digits_'
                        length={4}
                        validate={/^[0-9]$/}
                        format={key => key}
                        // onChange ={() => {}} 
                        onComplete={(code) => onComplete(code)}
                    />
                </div>
                <div className="forgot-pinbtn">
                    <a onClick={() => { history.push('/forgot-password') }}>Forgot PIN?</a>
                </div>
            </div>

            <div className="bottom-part">
                <p>Don’t have an Account? <a onClick={(e) => { history.push('/registartion/step-1') }}>Create Now</a></p>
                <a className="bottom-btn" onClick={handleOnSubmit}>Continue</a>
            </div>
        </>
    )

}

export default Login;