import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { arrowRightSideImg, bxsCoinStackImg, edit2Img, Info, leftArrowImg, locationImg, LogoIcon, OrderBlue, plusImg, WalletBlue } from "../../../utilities/images";

const Promotion = (props) => {
    const history = useHistory();

    useEffect(() => {
        // 
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/cart'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Review & Payment </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">
                    <div className="delvaddres-boxr">
                        <h2><img src={locationImg} alt="locationImg" />Add Delivery Address <Link to="#" onClick={() => history.push({ pathname: '/new-address', state: "placing" })}><img src={edit2Img} alt="edit2Img" /></Link></h2>
                    </div>

                    <div className="delvpaymnt-box">
                        <h2><img src={WalletBlue} alt="" /> Balance <Link to="#"><img src={plusImg} alt="plusImg" /></Link></h2>
                        <Link className="paymnt-dlbtn" to='/payment'><img src={LogoIcon} alt="LogoIcon" /> JBR<span>JBR 0.00</span></Link>
                    </div>

                    <div className="orderdlv-detailsbox">
                        <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Subtotal <span>$20.56</span></li>
                                <li>Discount <span>-$5.00</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt="Info" data-toggle="modal" data-target="#exampleModalCenter" /></small> <span>$1.00</span></li>
                                <li><Link to="#">Delivery fees </Link><span>$4.00</span></li>
                                <li><strong>Total</strong> <strong>$19.65</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to="/terms-&-conditions">terms & conditions</Link></h6>
                    </div>

                </div>

                <div className="selected-product checkout-box">
                    <div className="selected-itembox place-ordbtn place-misaddr">
                        <div className="selected-leftcnt">
                            <p><strong>Place Order</strong> <small>(Missing address)</small></p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link to='/tracking'>
                                <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Promotion;