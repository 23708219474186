import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { BellIcon, BurgerKing, deliveryParcelImg, discountOutlineImg, dotCircleImg, LeftCircle2, Rest1, Rest2, Rest3, Rest4, searchCircleImg, starCircleImg } from '../../../utilities/images'
import CustomModal from "../../components/shared/CustomModal";

const HomeRestaurent = () => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    return (
        <>
            <div className="main-content">
                <div className="store-bannerbg restaurent-bannerbg">
                    <div className="left-circle">
                        <Link to='/home-food'>
                            <img src={LeftCircle2} alt="" />
                        </Link>
                    </div>
                    <div className="store-iconrit rest-menu">
                        <Link to="#"><img src={searchCircleImg} alt="" /></Link>
                        <Link className="dot-menu" to="#"><img src={dotCircleImg} alt="" /></Link>
                        <div className="rest-popup">
                            <ul>
                                <li><Link to="#">Chicken Burger <span>13</span></Link></li>
                                <li><Link to="#">Combo <span>6</span></Link></li>
                                <li><Link to="#">Buy 1 Get 1 <span>6</span></Link></li>
                                <li><Link to="#">Appetizer <span>6</span></Link></li>
                                <li><Link to="#">House Special <span>6</span></Link></li>
                                <li><Link to="#">Pasta <span>6</span></Link></li>
                                <li><Link to="#">Veg Delights <span>6</span></Link></li>
                                <li><Link to="#">Flame-Grilled Beef Burger <span>6</span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="store-banner-part">
                    <div className="store-box">
                        <div className="store-item">
                            <img src={BurgerKing} alt="" />
                        </div>
                        <div className="store-cnt">
                            <h4>Burger King <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="" /> 4.5 (500+ ratings)</p>
                            <h6>e-vape store</h6>
                            <ul>
                                <li>Outlate <span>Little Havana</span></li>
                                <li>30 mins <Link to="#">Delivery to Miami <i className="fas fa-chevron-down"></i></Link></li>
                            </ul>
                            <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> $6.49 Delivery fee</Link></h3>
                        </div>
                    </div>
                    <div className="store-btn">
                        <Link to="#"><img src={discountOutlineImg} alt="" />
                            50% off upto $10</Link>
                    </div>
                </div>

                <div className="restaurant-mainprt">
                    <div className="restaurant-item" onClick={() => history.push('/home-restaurent-menu')} style={{ cursor: 'pointer' }}>
                        <div className="restaurant-left">
                            <h4>Fried Chicken</h4>
                            <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet. Crispy outside, Soft & Juicy Inside! It’s a great appetizer for lunch, or dinner, or in a combo for Kids.</p>
                        </div>
                        <div className="restaurant-right">
                            <img src={Rest1} alt="" />
                        </div>
                    </div>
                    <div className="restaurant-item" onClick={() => history.push('/home-restaurent-menu')} style={{ cursor: 'pointer' }}>
                        <div className="restaurant-left">
                            <h4>Fried Chicken</h4>
                            <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet. Crispy outside, Soft & Juicy Inside! It’s a great appetizer for lunch, or dinner, or in a combo for Kids.</p>
                        </div>
                        <div className="restaurant-right">
                            <img src={Rest2} alt="" />
                        </div>
                    </div>
                    <div className="restaurant-item" onClick={() => history.push('/home-restaurent-menu')} style={{ cursor: 'pointer' }}>
                        <div className="restaurant-left">
                            <h4>Fried Chicken</h4>
                            <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet. Crispy outside, Soft & Juicy Inside! It’s a great appetizer for lunch, or dinner, or in a combo for Kids.</p>
                        </div>
                        <div className="restaurant-right">
                            <img src={Rest3} alt="" />
                        </div>
                    </div>
                    <div className="restaurant-item" onClick={() => history.push('/home-restaurent-menu')} style={{ cursor: 'pointer' }}>
                        <div className="restaurant-left">
                            <h4>Fried Chicken</h4>
                            <p>Try our special Chicken Nuggets, made with juicy chicken breast fillet. Crispy outside, Soft & Juicy Inside! It’s a great appetizer for lunch, or dinner, or in a combo for Kids.</p>
                        </div>
                        <div className="restaurant-right">
                            <img src={Rest4} alt="" />
                        </div>
                    </div>
                    <div className="belllIcon" onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }}>
                        <img src={BellIcon} alt="bellicon" />
                    </div>
                </div>
            </div>
            {/* Start Model */}

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                isHomeFoodModal={true}
                child={
                    <div className='menuPopup'>
                        <ul>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Chicken Burger</Link>
                                <span>13</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Combo</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Buy 1 Get 1</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Appetizer</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>House Special</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Pasta</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Veg Delights</Link>
                                <span>6</span>
                            </li>
                            <li className='restaurent_menu'>
                                <Link to="/home-restaurent" onClick={handleOnCloseModal}>Flame-Grilled Beef Burger</Link>
                                <span>6</span>
                            </li>
                        </ul>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )
}

export default HomeRestaurent