import React, { useState, useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { arrowRightSideImg, LogoIcon3, bxsCoinStackImg, DeliveryMap, edit2Img, Gift, Info, leftArrowImg, locationImg, LogoIcon, OrderBlue, plusImg, WalletBlue } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";

const Promotion1 = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    useEffect(() => {
        // 
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/cart'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Review & Payment </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0<img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">
                    <div className="delvaddres-boxr">
                        <h2><img src={locationImg} alt="locationImg" />Delivery Address <Link to="#"><img src={edit2Img} alt="edit2Img" /></Link></h2>
                        <img className="delv-map" src={DeliveryMap} alt="deliveryMapImg" />
                        <h4>Home</h4>
                        <p>2598 West Street <br />Holland, MI 49424</p>
                        <Link to='/addresses' className="aprt-btn">Apartment 359 <i className="fas fa-chevron-right"></i></Link>
                    </div>

                    <div className="delvpaymnt-box">
                        <h2><img src={WalletBlue} alt="" /> Balance <Link to="#" onClick={() => { setModalDetail({ show: true}); setKey(Math.random()); }}><img src={plusImg} alt="plusImg" /></Link></h2>
                        <Link className="paymnt-dlbtn" to='/payment'><img src={LogoIcon} alt="LogoIcon" /> JBR<span>JBR 0.00</span></Link>
                    </div>

                    <div className="orderdlv-detailsbox">
                        <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Subtotal <span>$20.56</span></li>
                                <li>Discount <span>-$5.00</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt="Info" data-toggle="modal" data-target="#exampleModalCenter" /></small> <span>$1.00</span></li>
                                <li><Link to="#">Delivery fees </Link><span>$4.00</span></li>
                                <li><strong>Total</strong> <strong>$19.65</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to="terms-&-conditions.html">terms & conditions</Link></h6>
                    </div>

                </div>

                <div className="selected-product checkout-box">
                    <div className="selected-itembox place-ordbtn place-misaddr">
                        <div className="selected-leftcnt">
                            <p><strong>Place Order</strong> <small>(insufficient balance)</small></p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link to='/tracking'>
                                <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                            </Link>
                        </div>
                    </div>
                </div>

                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={true}
                    isRightSideModal={false}
                    walletModal={true}
                    child={
                        <div className="modal-content modal-full">
                            <div className="modal-header">
                                <button type="button" className="close" onClick={() => handleOnCloseModal()}>
                                    <span aria-hidden="true">Close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="coinplan-modal">
                                    <div className="coinp-mdlupr">
                                        <h2>Wallet</h2>
                                        <div className="header-right-item">
                                            <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                                        </div>
                                    </div>
                                    <div className="coinp-uprcnt">
                                        <p>Use coins to buy products from any listed stores and support/ tip to your rider</p>
                                    </div>

                                    <div className="coinp-refferbox">
                                        <img src={Gift} alt="" />
                                        <h4>Get 15.00 JBR</h4>
                                        <p>Get 15.00 JBR for every refaral </p>
                                        <Link to="#">Share</Link>
                                    </div>

                                    <div className="coinp-jbrbtn">
                                        <ul>
                                            <li>
                                                <Link to="#" onClick={()=>history.push({pathname:'/payment',state:"insufficient"})}>
                                                    <span className="jbrbtn-left">
                                                        <span><img src={LogoIcon3} alt="" /> JBR 10</span>
                                                    </span>
                                                    <span className="jbrbtn-right">
                                                        <span>USD $10 <i className="fas fa-chevron-right"></i></span>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" onClick={()=>history.push({pathname:'/payment',state:"insufficient"})}>
                                                    <span className="jbrbtn-left">
                                                        <span><img src={LogoIcon3} alt="" /> JBR 25</span>
                                                    </span>
                                                    <span className="jbrbtn-right">
                                                        <span>USD $25 <i className="fas fa-chevron-right"></i></span>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" onClick={()=>history.push({pathname:'/payment',state:"insufficient"})}>
                                                    <span className="jbrbtn-left">
                                                        <span><img src={LogoIcon3} alt="" /> JBR 50</span>
                                                    </span>
                                                    <span className="jbrbtn-right">
                                                        <span>USD $50 <i className="fas fa-chevron-right"></i></span>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" onClick={()=>history.push({pathname:'/payment',state:"insufficient"})}>
                                                    <span className="jbrbtn-left">
                                                        <span><img src={LogoIcon3} alt="" /> JBR 100</span>
                                                    </span>
                                                    <span className="jbrbtn-right">
                                                        <span>USD $100 <i className="fas fa-chevron-right"></i></span>
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="coinp-mdlbtmcnt">
                                        <p>If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>
        </>
    );
}

export default Promotion1;