import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg, ProductSt8, Fashion1, Fashion2, Fashion3, Fashion4, Fashion5, Fashion6, Fashion7, FashionBanner, FashionItem1, FashionItem2, FashionItem3, FashionItem4, Story1, starCircleImg, clockImg, deliveryParcelImg,
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { getFoodDataAsync, selectFoodData } from "./dashboardSlice"

const HomeFashion = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const foodData = useSelector(selectFoodData);

    let megaOffer = [1, 2, 3, 4]

    useEffect(() => {
        dispatch(getFoodDataAsync())
    }, [dispatch])
    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        <Link to='/dashboard'>
                            <img src={leftArrowImg} alt="" />
                        </Link>
                        <Link to="#">Maimi <i className="fas fa-chevron-down"></i></Link>
                        <p>Little Havana, Miami, FL 33130, USA</p>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>
            <div className="search-barhm">
                <form action="#" method="POST">
                    <input type="search" placeholder="Search here" />
                </form>
            </div>
            {/* category  */}
            <div className="retail-part retail-part2">
                <div className="retail-item">
                    <span>
                        <img src={Fashion1} alt="" />
                    </span>
                    <p>New Season</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion2} alt="" />
                    </span>
                    <p>Men</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion3} alt="" />
                    </span>
                    <p>Women</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion4} alt="" />
                    </span>
                    <p>Kids</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion5} alt="" />
                    </span>
                    <p>Beauty</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion6} alt="" />
                    </span>
                    <p>Footwear</p>
                </div>
                <div className="retail-item">
                    <span>
                        <img src={Fashion7} alt="" />
                    </span>
                    <p>Jewellery</p>
                </div>
                <div className="retail-item">
                    <Link to="#">
                        <img src={ProductSt8} alt="" />
                    </Link>
                    <p>More</p>
                </div>
            </div>
            {/* mega slider */}
            <div id='bannerSlider'>
                <OwlCarousel
                    items={1}
                    className="owl-theme"
                    loop={true}
                    nav={false}
                    dots={false}
                    autoplay={true}
                    autoplayTimeout={1500}
                    dotsdata="false"
                >
                    {
                        megaOffer && megaOffer.map((val, index) => {
                            return (
                                <div className="carousel-cell" key={index}>
                                    <div >
                                        <img className="d-block w-100" src={FashionBanner} alt="First slide" />
                                    </div>
                                    <div className="owl-dots dotstab d-block">
                                        {megaOffer?.map((val, index1) => {
                                            return (
                                                <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            </div>

            <div className="retail-mainprt">
                <div className="row">
                    <div className="col-6">
                        <div className="retail-product">
                            <img src={FashionItem1} alt="" />
                            <span>For Girl</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="retail-product">
                            <img src={FashionItem2} alt="" />
                            <span>For Him</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="retail-product">
                            <img src={FashionItem3} alt="" />
                            <span>For Girl</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="retail-product rtprod-lst">
                            <img src={FashionItem4} alt="" />
                            <span>For Boy </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* fashion delivery */}

            {foodData.length > 0 &&
                <div className="delivery-parthm p-bottom">
                    <div className="trending-title pt-5">
                        <h2>Top Stores</h2>
                    </div>
                    {foodData?.map((value, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push('/home-fashion-store')}>
                                    <img className="delv-prd" src={Story1} alt="" />
                                    <h4>Top Shoes</h4>
                                    <ul>
                                        <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                                        <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                                        <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value.upto}</li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        )
                    })
                    }
                </div>
            }
        </>
    )
}

export default HomeFashion