import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, supportProfImg, bxsCoinStackImg, galleryImg, uploadImg } from "../../../utilities/images";

const SupportRequest = (props) => {
    const history = useHistory();
    const [tabData, setTabData] = useState(1)
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [selectedFiles, setSelectedFiles] = useState();
    const [note, setNote] = useState("");
    console.log(userName,email,subject,selectedFiles)

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "userName") {
            setUserName(value);
        }
        else if (fieldName === "email") {
            setEmail(value.trim());
        }
        else if (fieldName === "subject") {
            setSubject(value);
        }
        else if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    const onSelectFiles = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
    }

    const onSubmit = () => {
        history.push('/my-support-request-details');
    }

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> My support request</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="support-reqtab">
                    <div className="tabs">
                        <div className="tab-upper">
                            <ul className="tab-links">
                                <li className={(tabData === 1) ? 'active' : ''} onClick={() => setTabData(1)}><Link to="#tab1">My support (1)</Link></li>
                                <li className={(tabData === 2) ? 'active' : ''} onClick={() => setTabData(2)}><Link to="#tab2">Support</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {(tabData === 1) ?
                                <div id="tab1" className="tab active">
                                    <div>
                                        <div className="support-rewtab1">
                                            <h2 style={{ cursor: 'pointer' }} onClick={() => history.push('/my-support-request-details')}>#XD1256P67 <span>Pending</span></h2>
                                            <div className="support-reqinner">
                                                <span>Subject</span>
                                                <h4><strong>Payment Issue</strong></h4>
                                                <p>Praesent a aliquam diam. Nullam nec feugiat turpis. Vestibulum fermentum in turpis et gravida. Phasellus feugiat ligula ut erat sodales.</p>
                                            </div>
                                            <div className="support-reqbtm">
                                                <span>Last Respond</span>
                                                <div className="support-reqbtmitem">
                                                    <img src={supportProfImg} alt="supportProfImg" />
                                                    <div className="support-reqbtmcnt">
                                                        <h4>Satomi D.</h4>
                                                        <p>13 Jun, 2022  |   12:25p</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="support-rewtab1">
                                            <h2 style={{ cursor: 'pointer' }} onClick={() => history.push('/my-support-request-details')}>#XD1256P67 <span className="completed">Completed</span></h2>
                                            <div className="support-reqinner">
                                                <span>Subject</span>
                                                <h4><strong>My account</strong></h4>
                                                <p>Praesent a aliquam diam. Nullam nec feugiat turpis. Vestibulum fermentum in turpis et gravida. Phasellus feugiat ligula ut erat sodales.</p>
                                            </div>
                                            <div className="support-reqbtm">
                                                <span>Last Respond</span>
                                                <div className="support-reqbtmitem">
                                                    <img src={supportProfImg} alt="supportProfImg" />
                                                    <div className="support-reqbtmcnt">
                                                        <h4>Satomi D.</h4>
                                                        <p>13 Jun, 2022  |   12:25p</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div>
                                    <div className="support-rewtab2">
                                        <div className="more-help-part">
                                            <div className="more-help-upper">
                                                <h4>Open new ticket</h4>
                                                <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                                            </div>

                                            <div className="more-help-form">
                                                <form action="#" method="POST" >
                                                    <input className="user" type="text" value="Michael R. Flowers" onChange={(e) => { onChangeInput(e, 'userName') }} />
                                                    <input className="email" type="text" value="Flowers@gmail.com" onChange={(e) => { onChangeInput(e, 'email') }} />
                                                    <select name="" id="" onChange={(e) => { onChangeInput(e, 'subject') }}>
                                                        <option value="Payment">Payment</option>
                                                        <option value="Delivery">Delivery</option>
                                                        <option value="Transfer">Transfer</option>
                                                        <option value="Order">Order</option>
                                                        <option value="">Other</option>
                                                    </select>

                                                    <textarea name="" id="" cols="30" rows="6" placeholder="Write here" value={note} onChange={(e) => { onChangeInput(e, 'note') }}></textarea>
                                                    <div className="upload-btn-wrapper upload-btn2">
                                                        <button className="filebtn" >
                                                            <img src={galleryImg} alt="galleryImg" />
                                                            <img src={uploadImg} alt="uploadImg" />
                                                            <span>Upload your files here</span>
                                                        </button>
                                                        <input type="file" name="myfile" onChange={(e) => { onSelectFiles(e) }} />
                                                    </div>
                                                    <button type="submit" className="submit-btn" onClick={(e) => { onSubmit(e) }} >Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportRequest;