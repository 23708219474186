import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAllBrands,
    getAllProducts,
    getAllOffers,
    getDashboardData,
    getFoodData,
    getRetailData,
    getFashionStoreData
} from "./dashboardApi";

const initialState = {
    brand: [],
    product: [],
    offer: [],
    dashboardDetails: {},
    foodData: [],
    retailData: [],
    fashionStore: [],
    status: {
        brand: 'idle',
        product: 'idle',
        offer: 'idle',
        dashboardDetails: 'idle',
        foodData: "idle",
        retailData: "idle",
        fashionStore: "idle"
    },
};

export const getBrandAsync = createAsyncThunk("dashboard/brand", async (data) => {
    const response = await getAllBrands();
    return response;
});
export const getProductAsync = createAsyncThunk("dashboard/product", async (data) => {
    const response = await getAllProducts();
    return response;
});
export const getOfferAsync = createAsyncThunk("dashboard/offer", async (data) => {
    const response = await getAllOffers();
    return response;
});
export const getDashboardAsync = createAsyncThunk("dashboard/dash", async (data) => {
    const response = await getDashboardData();
    return response;
});
export const getFoodDataAsync = createAsyncThunk("dashboard/food", async (data) => {
    const response = await getFoodData();
    return response;
});
export const getRetailDataAsync = createAsyncThunk("dashboard/retail", async (data) => {
    const response = await getRetailData();
    return response;
});
export const getFashionStoreDataAsync = createAsyncThunk("dashboard/fashionstore", async (data) => {
    const response = await getFashionStoreData();
    return response;
});

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getBrandAsync.pending, (state) => {
                state.status.brand = "loading";
            })
            .addCase(getBrandAsync.fulfilled, (state, action) => {
                state.status.brand = "idle";
                state.brand = action.payload;
            })
            .addCase(getProductAsync.pending, (state) => {
                state.status.product = "loading";
            })
            .addCase(getProductAsync.fulfilled, (state, action) => {
                state.status.product = "idle";
                state.product = action.payload;
            })
            .addCase(getOfferAsync.pending, (state) => {
                state.status.offer = "loading";
            })
            .addCase(getOfferAsync.fulfilled, (state, action) => {
                state.status.offer = "idle";
                state.offer = action.payload;
            })
            .addCase(getDashboardAsync.pending, (state) => {
                state.status.dashboardDetails = "loading";
            })
            .addCase(getDashboardAsync.fulfilled, (state, action) => {
                state.status.dashboardDetails = "idle";
                state.dashboardDetails = action.payload;
            })
            .addCase(getFoodDataAsync.pending, (state) => {
                state.status.foodData = "loading";
            })
            .addCase(getFoodDataAsync.fulfilled, (state, action) => {
                state.status.foodData = "idle";
                state.foodData = action.payload;
            })
            .addCase(getRetailDataAsync.pending, (state) => {
                state.status.retailData = "loading";
            })
            .addCase(getRetailDataAsync.fulfilled, (state, action) => {
                state.status.retailData = "idle";
                state.retailData = action.payload;
            })
            .addCase(getFashionStoreDataAsync.pending, (state) => {
                state.status.fashionStore = "loading";
            })
            .addCase(getFashionStoreDataAsync.fulfilled, (state, action) => {
                state.status.fashionStore = "idle";
                state.fashionStore = action.payload;
            })
    },
});

export const selectBrandData = (state) => state.dashboard.brand;
export const selectProductData = (state) => state.dashboard.product;
export const selectOfferData = (state) => state.dashboard.offer;
export const selectDashboardData = (state) => state.dashboard.dashboardDetails;
export const selectFoodData = (state) => state.dashboard.foodData;
export const selectRetailData = (state) => state.dashboard.retailData;
export const selectFashionStoreData = (state) => state.dashboard.fashionStore;

export default dashboardSlice.reducer;