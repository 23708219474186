import React from 'react'
import {
    Food1, Food2, Food3, Food4, Food5, Food6, Food7, FoodSlider, ProductSt8, TrendingFood5, starCircleImg, clockImg, deliveryParcelImg
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { Link, useHistory } from 'react-router-dom';

const FoodReservation = () => {
    const history = useHistory()
    let megaOffer = [1, 2, 3, 4]

    return (
        <div id="tab1" className="active">
            <div className="home-tab1prt">
                <div className="search-barhm">
                    <form action="#" method="POST">
                        <input type="search" placeholder="Search here" />
                    </form>
                </div>

                <div className="retail-part retail-part2">
                    <div className="retail-item">
                        <span>
                            <img src={Food1} alt="" />
                        </span>
                        <p>Fast food</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food2} alt="" />
                        </span>
                        <p>Pizza</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food3} alt="" />
                        </span>
                        <p>Coffee</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food4} alt="" />
                        </span>
                        <p>Indian food</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food5} alt="" />
                        </span>
                        <p>Soup</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food6} alt="" />
                        </span>
                        <p>Salad</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={Food7} alt="" />
                        </span>
                        <p>Drinks </p>
                    </div>
                    <div className="retail-item">
                        <Link to="#">
                            <img src={ProductSt8} alt="" />
                        </Link>
                        <p>More</p>
                    </div>
                </div>

                <div id="bannerSlider">
                    <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={1500}
                        slidespeed={100}
                        dotsdata="false"
                        margin={1}
                    >
                        {
                            megaOffer && megaOffer.map((val, index) => {
                                return (
                                    <div className="carousel-cell" key={index}>
                                        <img src={FoodSlider} alt="" />
                                        <div className="owl-dots dotstab d-block">
                                                    {megaOffer?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

                <div className="delivery-parthm" onClick={() => history.push('/restaurent-reservation')} style={{ cursor: 'pointer' }}>
                    <div className="delivery-sechm">
                        <img className="delv-prd" src={TrendingFood5} alt="" />
                        <h4>Harfy</h4>
                        <ul>
                            <li><img src={starCircleImg} alt="" /> 4.5</li>
                            <li><img src={clockImg} alt="" /> 26-50 min</li>
                            <li><img src={deliveryParcelImg} alt="" /> $6.49 Delivery fee</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodReservation