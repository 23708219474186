import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, DeliveryMap, rider2, LocationDark, orderImg, Info } from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';

const OrdersPlaced = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false
        });
        setKey(Math.random());
    }
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/dashboard'><img src={leftArrowImg} alt="" /></Link> Home </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">1.35 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">
                    <div className="product-delvbox">
                        <img src={rider2} alt="" />
                        <div className="rider-cnt">
                            <h6>Delivery time</h6>
                            <h2>1 hour delivery</h2>
                            <p>9:00p-10:00p <Link to='/changing-time-slot'>Change time window</Link></p>
                        </div>
                    </div>
                    <div className="delvaddres-boxr">
                        <h2><img src={LocationDark} alt="" />Delivery Address </h2>
                        <img className="delv-map" src={DeliveryMap} alt="" />
                        <h4>Home</h4>
                        <p>2598 West Street <br />Holland, MI 49424</p>
                        <Link to='/addresses' className="aprt-btn">Apartment 359 <i className="fas fa-chevron-right"></i></Link>
                    </div>

                    <div className="orderdlv-detailsbox mt-4">
                        <h2><img src={orderImg} alt="" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Subtotal <span>$20.56</span></li>
                                <li>Discount <span>-$5.00</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt=""  onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }} /></small> <span>$2.00</span></li>
                                <li>Delivery fees <span>$4.00</span></li>
                                <li>Extra charge <span>$5.00</span></li>
                                <li><strong>Total</strong> <strong>$24.65</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to='/terms-and-conditions'>terms & conditions</Link></h6>
                    </div>
                </div>

                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={true}
                    isRightSideModal={true}
                    child={<div className="modal-content">
                        <div className="modal-header address-mdlheader">
                            <h5><strong>Whats included?</strong></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleOnCloseModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body address-mdlbody pt-0">
                            <div className="taxes-mdlbox-cnt">
                                <h4>Service fees <span>$1.50</span></h4>
                                <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                            </div>
                            <div className="taxes-mdlbox-cnt">
                                <h4>Taxes <span>$1.50</span></h4>
                            </div>
                        </div>
                    </div>
                    }
                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>

        </div>
    )
}

export default OrdersPlaced