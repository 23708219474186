import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    leftArrowImg, coinStackImg, bellImg, bagImg
} from "../../../utilities/images";
import DashboardDelivery from "./DashboardDelivery";
import DashboardPickup from "./DashboardPickup";
import DashboardShipping from "./DashboardShipping";

const OldIndex = (props) => {
    const history = useHistory();

    const [tabView, setTabView] = useState(1);


    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        <a href="#">
                            <img src={leftArrowImg} alt="leftArrow" />
                        </a>
                        <a href="#">Maimi <i className="fas fa-chevron-down"></i></a>
                        <p>Little Havana, Miami, FL 33130, USA</p>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => { history.push('/notifications') }}><img src={bellImg} alt="bellImg" /></button>
                    <button type="button" onClick={()=>history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                    <a href="#">0
                        <img src={coinStackImg} alt="coinStack" /></a>
                </div>
            </div>

            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabView == 1) ? `active` : ``}><a href={void(0)} onClick={() => { setTabView(1) }}>Delivery</a></li>
                                <li className={(tabView == 2) ? `active` : ``}><a href={void(0)} onClick={() => { setTabView(2) }}>Pickup</a></li>
                                <li className={(tabView == 3) ? `active` : ``}><a href={void(0)} onClick={() => { setTabView(3) }}>Shipping</a></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {(tabView == 1) &&
                                <div id="tab1" className={(tabView == 1) ? `tab active` : `tab`}>
                                    <DashboardDelivery />
                                </div>}
                            {(tabView == 2) &&
                                <div id="tab2" className={(tabView == 2) ? `tab active` : `tab`}>
                                    <DashboardPickup />
                                </div>
                            }
                            {tabView == 3 &&
                                <div id="tab3" className={(tabView == 3) ? `tab active` : `tab`}>
                                    <DashboardShipping/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OldIndex