import React from "react";
import { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import Add from './add';
import { homeWhiteImg, edit2Img, locationImg, addressMapImg, homeImg, invoiceImg } from "../../../utilities/images";

const NewAddress = (props) => {
    const history = useHistory();
    const [apartment, setApartment] = useState("");
    const [floorNo, setFloorNo] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "Apartment") {
            setApartment(value);
        }
        else if (fieldName === "FloorOrAprNo") {
            setFloorNo(value);
        }
    }

    return (
        <>
            <div className="address-map">
                <img src={addressMapImg} alt="addressMapImg" />
            </div>
            <div className="main-content">
                <div className="delivery-details">
                    <div className="delivery-dtupper">
                        <h2>Delivery details</h2>
                        <p>
                            <img src={locationImg} alt="locationImg" />
                            <span>2598 West Street <br />Holland, MI 49424</span>
                            <Link to="#" onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }}>
                                <img src={edit2Img} alt="edit2Img" />
                            </Link>
                        </p>
                        <form action="#" method="POST" >
                            <input type="text" placeholder="Apartment" onChange={(e) => { handleChange(e, 'Apartment') }} value={apartment} />
                            <input type="text" placeholder="Floor or Apr No (Optional)" onChange={(e) => { handleChange(e, 'FloorOrAprNo') }} value={floorNo} />
                        </form>
                    </div>
                    <div className="delivery-btmprt">
                        <h4>Add label</h4>
                        <ul>
                            <li>
                                <Link to="#" className="active"><span><img src={homeWhiteImg} alt="homeWhiteImg" /></span> Home</Link>
                            </li>
                            <li>
                                <Link to="#"><span><img src={homeImg} alt="homeImg" /></span> Work</Link>
                            </li>
                            <li>
                                <Link to="#"><span><img src={invoiceImg} alt="invoiceImg" /></span> Billing</Link>
                            </li>
                            <li>
                                <Link to="#"><span><i className="fas fa-plus"></i></span> Other</Link>
                            </li>
                        </ul>
                        <div className="delivery-adrbtn">
                            <Link
                                to="#" onClick={() => { (props?.location?.state === "placing") ? history.push('/placing') : history.push('/addresses') }}
                            >Save</Link>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={true}
                isRightSideModal={false}
                mediumWidth={false}
                child={modalDetail.flag === 'add'
                    ? <Add closeModal={() => handleOnCloseModal()} data={props?.location?.state} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}


export default NewAddress;