import React from "react";
import { Link, useHistory } from 'react-router-dom';
import {leftArrowImg, bxsCoinStackImg ,home2Img, edit2Img, workImg } from "../../../utilities/images";

const Address = (props) => {
    const history = useHistory();

  return (
        <>
        <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => {history.goBack()}}><img src={leftArrowImg} alt="leftArrowImg"  /></Link> Addresses</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="address-part">
                    <form action="#" method="POST">
                        <div className="address-item">
                            <div className="radio-btn">
                                <img src={home2Img} alt="home2Img" />
                            </div>
                            <div className="address-cnt">
                                <h4>Home</h4>
                                <p>2598 West Street <br/>Holland, MI 49424</p>
                            </div>
                            <div className="edit-btn">
                                <Link to="#">
                                    <img src={edit2Img} alt="edit2Img" />
                                </Link>
                            </div>
                        </div>
                        <div className="address-item">
                            <div className="radio-btn">
                                <img src={workImg} alt="workImg" />
                            </div>
                            <div className="address-cnt">
                                <h4>Work</h4>
                                <p>2598 West Street <br />Holland, MI 49424</p>
                            </div>
                            <div className="edit-btn">
                                <Link to="#">
                                    <img src={edit2Img} alt="edit2Img" />
                                </Link>
                            </div>
                        </div>                         
                    </form>
                    <div className="address-btn" onClick={() => {history.push({pathname:'/new-address',state:props?.location?.state})}}>
                        <Link to="#">Add new address</Link>
                    </div>
                </div>                
            </div>
        </>
  );
}  

export default Address;