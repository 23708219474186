import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { deliveryParcelImg, Cicle, discountOutlineImg, dotCircleImg, LeftCircle2, searchCircleImg, SportShoes, starCircleImg, AddIcon, More1 } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getFashionStoreDataAsync, selectFashionStoreData } from './dashboardSlice';


const HomeFashionStore = () => {
    const history = useHistory()
    const fashionStore = useSelector(selectFashionStoreData)
    const dispatch = useDispatch()
    let megaOffer = [1, 2, 3, 4]

    useEffect(() => {
        dispatch(getFashionStoreDataAsync())
    }, [dispatch])

    return (
        <div className="main-content pb-130">
            <div className="store-bannerbg fashion-storebg">
                <div className="left-circle">
                    <Link to="/home-fashion">
                        <img src={LeftCircle2} alt="" />
                    </Link>
                </div>
                <div className="store-iconrit">
                    <Link to="#"><img src={searchCircleImg} alt="" /></Link>
                    <Link to="#"><img src={dotCircleImg} alt="" /></Link>
                </div>
            </div>
            <div className="store-banner-part">
                <div className="store-box">
                    <div className="store-item">
                        <img src={SportShoes} alt="" />
                    </div>

                    <div className="store-cnt">
                        <h4>Shoe factory <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                        <p><img src={starCircleImg} alt="" /> 4.5 (500+ ratings)</p>
                        <h6>e-vape store</h6>
                        <ul>
                            <li>Outlate <span>Little Havana</span></li>
                            <li>30 mins <Link to="#">Delivery to Miami <i className="fas fa-chevron-down"></i></Link></li>
                        </ul>
                        <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> $6.49 Delivery fee</Link></h3>
                    </div>
                </div>
                <div className="store-btn">
                    <Link to="#"><img src={discountOutlineImg} alt="" />
                        50% off upto $10</Link>
                </div>
            </div>
            <div id="bannerSlider">
                <OwlCarousel
                    items={1}
                    className="owl-theme"
                    loop={true}
                    nav={false}
                    dots={false}
                    autoplay={true}
                    autoplayTimeout={1500}
                    dotsdata="false"
                >
                    {
                        megaOffer && megaOffer.map((val, index) => {
                            return (
                                <div className="carousel-cell" key={index}>
                                    <img className="d-block w-100" src={Cicle} alt="" />
                                    <div className="owl-dots dotstab d-block">
                                        {megaOffer?.map((val, index1) => {
                                            return (
                                                <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            </div>

            {
                (fashionStore.length > 0) ? <>
                    <div className="fashion-mainprt">
                        <div className="row">
                            {fashionStore.map((val, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="col-6">
                                            <div className={(index === 3) ? "fashion-item fash-margtop" : (index === 5) ? "fashion-item fash-margtop2" : (index === 7) ? "fashion-item fash-margtop3" : "fashion-item"}>
                                                <div className="fashion-slider" id="fashionSlider">
                                                    <OwlCarousel items={1}
                                                        className="owl-theme main-carousel"
                                                        nav={false}
                                                        loop={true}
                                                        dots={true}
                                                        autoplay={true}
                                                        autoplayTimeout={2000}
                                                        margin={1} >
                                                        {
                                                            val?.image && val?.image?.map((val, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <img src={val} alt="" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </OwlCarousel>
                                                </div>
                                                <div className="fashion-cnt">
                                                    <div className="fashion-color">
                                                        <span className="red active"></span>
                                                        <span className="info"></span>
                                                        <span className="black"></span>
                                                        <span className="dark"></span>
                                                        <span className="white"></span>
                                                    </div>
                                                    <p style={{ cursor: 'pointer' }} onClick={() => history.push('/home-fashion-store-product')}><strong>{val?.title}</strong> {val?.description}</p>
                                                    <ul>
                                                        <li>X</li>
                                                        <li className="active">L</li>
                                                        <li>XL</li>
                                                        <li>XXL</li>
                                                    </ul>
                                                    <h4>${val?.price}</h4>
                                                    <Link to="#">
                                                        <img src={AddIcon} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </> : ""
            }
            <div className="belllIcon">
                <img src={More1} alt="more" />
            </div>
        </div>
    )
}

export default HomeFashionStore