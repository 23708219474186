import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory} from 'react-router-dom';
import { bxsCoinStackImg, leftArrowImg, orderWhiteImg } from "../../../utilities/images";



const Tracking = (props) => {
    const history = useHistory();


    useEffect(() => {
       // 
    }, [])

    return (
        <>
          <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => {history.push('/order-processing')}}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="assinged-main assinged-mainbg2 track-bg">
                    <div className="assinged-overlay">
                        <div className="assinged-delivery">
                            <div className="assinged-box">
                                <div className="assinged-boxupper">
                                    <div className="assinged-boxuper-left">
                                        <h4>Order status</h4>
                                        <p>Preparing your order</p>
                                    </div>
                                    <div className="assinged-boxuper-right">
                                        <Link to="#" onClick={() => {history.push('/tracking-two')}}><img src={orderWhiteImg} alt="orderWhiteImg" /> Order</Link>
                                    </div>
                                </div>

                                <div className="track-timeline">
                                    <ul>
                                        <li className="active-timeline pt-4">
                                            <h4>Order accepted</h4>
                                            <p>21 Jun, 2022  |   10:30 am</p>
                                        </li>
                                    </ul>
                                </div>                                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tracking;