import React, {useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    bagImg, bxsCoinStackImg
} from "../../../utilities/images";

const Scan = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push('/scan-result')
          }, "1000")
    }, [history])
    
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4>Scan</h4>
                </div>
                <div className="header-right-item">
                    <button type="button"><img src={bagImg} alt="" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>
            <div className="scan-mainprt">
                <div className="scan-boxn">
                    <span>Scaning barcode...</span>
                </div>
            </div>
        </div>
    )
}

export default Scan