import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import workingImg from "../../public/images/working.png";
import lefArrowImg from "../../public/images/left-arrow.svg";
import verifyImg from "../../public/images/verify.png";
import CustomModal from "../../components/shared/CustomModal";

const CheckingAgeVerification = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    

    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <div className="age-upper">
                <Link to="#" onClick={() => {history.push('/age-verification')}}><img src={lefArrowImg} alt="lefArrowImg" /> Age verification</Link>
            </div>

            <div className="ageverify4-main">
                <img src={workingImg} alt="workingImg" />
                <div className="ageverify4-cnt">
                    <h4>We are working on you age check</h4>
                    <p>We ‘ll get back to you soon</p>
                </div>
            </div>

            <div className="age-bottom">
                <Link to="#" onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }} >We’ll notify you in 5-8 minutes </Link>
               
            </div>
            <CustomModal
                key = {key}
                show = {modalDetail.show}
                backdrop = "static"
                showCloseBtn= {true}
                isRightSideModal={true}
                child = {
                        <div className="modal-body">
                            {/* <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() =>{props.closeModal()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-mainprt">
                                <div className="mdlverify-cnt">
                                    <h4>Verified</h4>
                                    <img src={verifyImg} alt="verifyImg" />
                                    <p>You are verified!</p>
                                    <h6>23 Yrs 3 month old</h6>
                                </div>
                                <div className="mdlverify-btn">
                                    <Link to="#">Update info</Link>
                                    <Link to="#" onClick={() => {history.push('/more')}} data-dismiss="modal" className="active">Let’s browse</Link>
                                </div>
                            </div>
                        </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            /> 
        </>
    )

}

export default CheckingAgeVerification;


