import React, { useEffect } from 'react'
import { RetailItem1, RetailItem2, offerCrsImg12, starCircleImg, clockImg, RetailItem3, RetailItem4, RetailProduct1, RetailProduct2, RetailProduct3, RetailProduct4, sliderOneImg, Trending1 } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { getOfferAsync, selectOfferData, selectRetailData, getRetailDataAsync } from "./dashboardSlice"
import { useHistory } from 'react-router-dom';

const RetailDelivery = () => {

    let megaOffer = [1, 2, 3, 4]

    const dispatch = useDispatch();
    const history = useHistory()
    const offerData = useSelector(selectOfferData);
    const retailData = useSelector(selectRetailData);

    useEffect(() => {
        dispatch(getOfferAsync())
        dispatch(getRetailDataAsync())
    }, [])
    return (
        <div id="tab1" className="tab active">
            <div className="home-tab1prt">
                <div className="search-barhm">
                    <form action="#" method="POST">
                        <input type="search" placeholder="Search here" />
                    </form>
                </div>
                {/* categories */}
                <div className="retail-part">
                    <div className="retail-item">
                        <span>
                            <img src={RetailItem1} alt="" />
                        </span>
                        <p>Book</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={RetailItem2} alt="" />
                        </span>
                        <p>Grocery</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={RetailItem3} alt="" />
                        </span>
                        <p>Smoke</p>
                    </div>
                    <div className="retail-item">
                        <span>
                            <img src={RetailItem4} alt="" />
                        </span>
                        <p>Sports</p>
                    </div>
                </div>
                {/* mega offer slider */}
                <div id="bannerblueSlider">
                    <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={1500}
                        slidespeed={100}
                        dotsdata="false"
                    >
                        {
                            megaOffer && megaOffer.map((val, index) => {
                                return (
                                    <div className="carousel-cell" key={index}>
                                        <div>
                                            <img className="d-block w-100" src={sliderOneImg} alt="First slide" />
                                        </div>
                                        <div className="owl-dots dotstab d-block">
                                            {megaOffer?.map((val, index1) => {
                                                return (
                                                    <button role="button" className={index == index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index == index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

                {/* products */}
                <div className="retail-mainprt">
                    <div className="row">
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={RetailProduct1} alt="" />
                                <span>Hookah</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={RetailProduct2} alt="" />
                                <span>Beverage</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product">
                                <img src={RetailProduct3} alt="" />
                                <span>Cereal</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="retail-product rtprod-lst">
                                <img src={RetailProduct4} alt="" />
                                <span>Vape</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* offers */}

                {offerData?.length > 0 ?
                    <div className="offer-areahm">
                        <div className="offer-title">
                            <h2>Offers</h2>
                        </div>
                        <div className='container-fluid' >
                            <OwlCarousel items={2.5}
                                className="owl-theme"
                                nav={false}
                                dots={false}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                                margin={0} >
                                {offerData?.map((offerData, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell">
                                                <div className="offer-crslpart">
                                                    <div className="offer-crslitem">
                                                        <img src={offerCrsImg12} alt="offerCrsImg1" />
                                                        <div className="offer-crslinner">
                                                            <h2>{offerData?.off}</h2>
                                                            <p>Upto {offerData?.upto} <span>AD</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="offer-crslcnt">
                                                        <h4>{offerData?.title}</h4>
                                                        <ul>
                                                            <li><img src={starCircleImg} alt="starCircleImg" />{offerData?.rating}</li>
                                                            <li><img src={clockImg} alt="clockImg" /> {offerData?.time}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </OwlCarousel>
                        </div>
                    </div>
                    : ""}

                {/* retail shop */}
                {retailData.length > 0 ?
                    <div className="delivery-parthm">
                        <div className="trending-title pt-5">
                            <h2>Trending</h2>
                        </div>
                        {retailData?.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="delivery-sechm" onClick={() => history.push('/store')} style={{ cursor: 'pointer' }}>
                                        <img className="delv-prd" src={Trending1} alt="" />
                                        <h4>{value.title}</h4>
                                        <ul>
                                            <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                                            <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                                            <li><img src={RetailItem2} alt="category" />{value.category}</li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div> : ""
                }
            </div>
        </div>
    )
}

export default RetailDelivery