import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, galleryImg, uploadImg } from "../../../utilities/images";

const ReportAndIssue = (props) => {
    const history = useHistory();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [selectedFiles, setSelectedFiles] = useState();
    const [note, setNote] = useState("");

    console.log(userName,email,subject, selectedFiles);
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "userName") {
            setUserName(value);
        }
        else if (fieldName === "email") {
            setEmail(value.trim());
        }
        else if (fieldName === "subject") {
            setSubject(value);
        }
        else if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    const onSelectFiles = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
    }

    const onSubmit = () => {
        history.push('/report-an-issue-two')
    }

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Report other issue</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="more-help-part">
                    <div className="more-help-upper">
                        <h4>Report other issue</h4>
                        <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                    </div>

                    <div className="more-help-form">
                        <form action="#" method="POST">
                            <input className="user" type="text" value="Michael R. Flowers" onChange={(e) => { onChangeInput(e, 'userName') }} />
                            <input className="email" type="text" value="Flowers@gmail.com" onChange={(e) => { onChangeInput(e, 'email') }} />
                            <select name="subject" id="" onChange={(e) => { onChangeInput(e, 'subject') }}>
                                <option value="">Select subject</option>
                                <option value="">Payment</option>
                                <option value="">Delivery</option>
                                <option value="">Transfer </option>
                                <option value="">Order</option>
                                <option value="">Others</option>
                            </select>
                            <textarea name="sub_description" id="" cols="30" rows="6" placeholder="Write here" value={note} onChange={(e) => { onChangeInput(e, 'note') }}></textarea>
                            <div className="upload-btn-wrapper upload-btn2">
                                <button className="filebtn">
                                    <img src={galleryImg} alt="galleryImg" />
                                    <img src={uploadImg} alt="uploadImg" />
                                    <span>Upload your files here</span>
                                </button>
                                <input type="file" name="myfile" onChange={(e) => { onSelectFiles(e) }} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={(e) => { onSubmit(e) }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportAndIssue;