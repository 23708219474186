import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { leftArrowImg, editProfileImg, bxsCoinStackImg, profileImageImg, user2Img, emailImg, tellImg, verifyImg, calender2Img } from "../../../utilities/images";
import PhoneInput from 'react-phone-input-2'
import { toast } from "react-toastify";

const EditProfile = (props) => {
    const history = useHistory();
    // const [image, setImage] = useState({});
    const [document, setDocument] = useState("")
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");

    const onChangePhoneNumber = (value, data, event, formattedValue) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
    }

    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState({
        userName: "Michael R. Flowers",
        email: "Flowers@gmail.com",
        mobile: "11111111111",
        dob: "1998-09-07"
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "sdf" });
    }

    const handleCheck = () => {
        if (!formData.userName) {
            toast.error("Please enter your user name");
            return;
        }
        else if (formData.userName.length < 3) {
            toast.error("Username should be of 3 digits");
            return;
        }
        else if (!formData.email) {
            toast.error("Please enter your email");
            return;
        }
        else if (!formData.mobile) {
            toast.error("Please enter phone number");
            return;
        }
        else if (formData && formData.mobile.length <= 4) {
            toast.error("Phone number length should be of 5 to 15 digits");
            return;
        }
        history.push('/profile')
    }

    const handleProfileChange = (e) => {
        const imageFile = e.target.files[0];
        setDocument(URL.createObjectURL(imageFile));
    }

    // const photoUpload = e => {
    //     e.preventDefault();
    //     const reader = new FileReader();
    //     const file = e.target.files[0];handleProfileChange
    //             file: file,
    //             imagePreviewUrl: reader.result
    //         });
    //     }
    //     reader.readAsDataURL(file);
    // }

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><a onClick={() => { history.push('/profile') }}><img src={leftArrowImg} alt="leftArrowImg" /></a> Profile</h4>
                </div>
                <div className="header-right-item">
                    <a onClick={() => handleCheck()}>Save</a>
                </div>
            </div>

            <div className="main-content">
                <div className="upload-btn-wrapper upload-btn-wrapper2">
                    <div className="profile-imageprt">
                        <img src={document ? document : editProfileImg} alt="editProfileImg" />
                        <input type="file" name="myfile" style={{ opacity: '0' }} onChange={(e)=> handleProfileChange(e)} />
                    </div>
                </div>

                <div className="profile-describe">
                    <form action="#" method="POST">
                        <div className="profile-info">
                            <label htmlFor="">Name</label>
                            <p><img src={user2Img} alt="user2Img" /><input className="user-name p-edit" type="text" id="r1" value={formData.userName} name="userName" onChange={(e) => { handleChange(e, 'userName') }} /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Email address</label>
                            <p><img src={emailImg} alt="emailImg" /> <input className="user-name p-edit" type="text" id="r2" value={formData.email} name="email" onChange={(e) => { handleChange(e, 'email') }} /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Phone number</label>
                            <p><img src={tellImg} alt="tellImg" /> 612717070 <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">SSN</label>
                            <p><img src={calender2Img} alt="calender2Img" /> -------5698 <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Date of Birth</label>
                            <p><img src={calender2Img} alt="calender2Img" />
                                <input className="birth p-edit" type="date" id="r3" value={formData.dob} placeholder="mm / dd / yyyy" name="birth" onChange={(e) => { handleChange(e, 'birth') }} />
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}


export default EditProfile;