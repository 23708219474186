import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';

const ReservationDateModal = (props) => {
  const [value, onChange] = useState(new Date());
  return (
    <div className="modal-content">
      <div className="modal-header modal-headerbok">
        <button type="button" className="close" onClick={props.close}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="time-mdlmain">
          <h2>Please chose a date below:</h2>

          <div className="time-mdlprt">
            <div>
              <Calendar onChange={onChange} value={value} />
            </div>
          </div>

          <div className="reserve-btn mt-auto">
            <Link to="#" onClick={() => props.details('reservetable')}>Set Date</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservationDateModal