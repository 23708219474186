import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { riderImg, leftArrowImg,selectCard1Img, arrowBlueImg, Info } from "../../../utilities/images";

const Cart = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [count, setCount] = useState({
        product1 : 1,
        product2 : 1,
        product3: 1
    })

    const handleIncrement = (data) => {
        const name = data;
        setCount({
            ...count,
            [name]: count[data] + 1 
          })
    }
    const handleDecrement = ( data) => {
        const name = data;
        setCount({
            ...count,
            [name]: count[data] -1
          })
    }

    const handleChange = (e) => {
        const name = e.target.name;
        setCount({
            ...count,
            [name]: e.target.value
          })
    }

    useEffect(() => {
        
    }, [])

    return (
        <>
          <div className="order-heading">
                <h4><a onClick={() => {history.goBack()}}><img src={leftArrowImg} alt="leftArrowImg" /></a> Checkout </h4>
            </div>

            <div className="main-content cart-mainpd">
                <div className="product-delivery-area">
                    <div className="product-delvbox">
                        <img src={riderImg} alt="riderImg" />
                        <div className="rider-cnt">
                            <h6>Delivery time</h6>
                            <h2>2 hour delivery windows</h2>
                            <p>2:00-4:00 pm <a onClick={()=> history.push('/delivery-types')}>Change</a></p>
                        </div>
                    </div>

                    <div className="select-card">
                        <img src={selectCard1Img} alt="selectCard1Img" />
                        <div className="select-cardcnt">
                            <h4>Marlboro Red Gold</h4>
                            <p>Packet</p>
                            <div className="quantity">
                                <form action="#" method="POST">
                                    <span className="input-number-decrement" onClick={()=>handleDecrement("product1")}><i className="fas fa-minus"></i></span>
                                    <input className="input-number4" name="product1" type="text" value={count.product1} onChange={(e)=>handleChange(e)}/>
                                    <span className="input-number-increment" onClick={()=>handleIncrement("product1")}><i className="fas fa-plus"></i></span>
                                </form>                           
                            </div>
                        </div>
                        <div className="select-cardrit">
                            <span><i className="fas fa-times"></i></span>
                            <h6>$6.56</h6>
                        </div>
                    </div>
                    <div className="select-card">
                        <img src={selectCard1Img} alt="selectCard1Img" />
                        <div className="select-cardcnt">
                            <h4>Marlboro Red Gold</h4>
                            <p>Packet</p>
                            <div className="quantity">
                                <form action="#" method="POST">
                                    <span className="input-number-decrement" onClick={()=>handleDecrement("product2")}><i className="fas fa-minus"></i></span>
                                    <input className="input-number5" name="product2" type="text" value={count.product2} onChange={(e)=>handleChange(e)}/>
                                    <span className="input-number-increment" onClick={()=>handleIncrement("product2")}><i className="fas fa-plus"></i></span>
                                </form>                           
                            </div>
                        </div>
                        <div className="select-cardrit">
                            <span><i className="fas fa-times"></i></span>
                            <h6>$6.56</h6>
                        </div>
                    </div>
                    <div className="select-card">
                        <img src={selectCard1Img} alt="selectCard1Img" />
                        <div className="select-cardcnt">
                            <h4>Marlboro Red Gold</h4>
                            <p>Packet</p>
                            <div className="quantity">
                                <form action="#" method="POST">
                                    <span className="input-number-decrement" onClick={()=>handleDecrement("product3")}><i className="fas fa-minus"></i></span>
                                    <input className="input-number6" name="product3" type="text" value={count.product3} onChange={(e)=>handleChange(e)}/>
                                    <span className="input-number-increment" onClick={()=>handleIncrement("product3")}><i className="fas fa-plus"></i></span>
                                </form>                           
                            </div>
                        </div>
                        <div className="select-cardrit">
                            <span><i className="fas fa-times"></i></span>
                            <h6>$6.56</h6>
                        </div>
                    </div>

                    <div className="apply-couponbox">
                        <a onClick={()=>history.push('/coupons-two')}>
                            <h4>Apply Coupon <small>Add your coupon here</small></h4>
                            <span><i className="fas fa-chevron-right"></i></span>
                        </a>                        
                    </div>

                    <div className="cart-totalbox">
                        <ul>
                            <li>Sub Total <span>$20.65</span></li>
                            <li>Coupon <span>-$5.00</span></li>
                            <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$1.00</span></li>
                            <li>Delivery fees <span>$5.00</span></li>
                            <li><strong>Total</strong> <strong>$19.65</strong></li>
                        </ul>
                    </div>
                </div>    
                <div className="selected-product checkout-box">
                    <div className="selected-itembox">
                        <div className="selected-leftcnt">
                           <p><strong>Continue to checkout</strong></p>
                        </div>
                        <div className="selected-rightbtn">
                            <a onClick={() => history.push('/delivery-types-2')}>
                                <img src={arrowBlueImg} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>                                                  
            </div>
        </>
     );
}

export default Cart;
