import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import Add from './add';
import { leftArrowImg, SearchImg, bxsCoinStackImg, addCartP1Img } from "../../../utilities/images";
import { getStoreAsync, selectStoreData } from "./productAddToCartSlice"
import { useSelector, useDispatch } from "react-redux";

const ProductAddToCart = (props) => {
    const history = useHistory();
    // const [Cigarettes, setCigarettes] = useState([]);
    const [key, setKey] = useState(Math.random());
    const [selectedProduct, setSelectedProduct] = useState('Marlboro')
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const dispatch = useDispatch();
    const Cigarettes = useSelector(selectStoreData);

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    useEffect(() => {
        dispatch(getStoreAsync())
    }, [dispatch])

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Cigarettes</h4>
                </div>
                <div className="header-right-item">
                    <button>
                        <img src={SearchImg} alt="SearchImg" />
                    </button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="product-tabarea">
                    <div className="tabs">
                        <div className="tab-upper">
                            <ul className="tab-links tab-menu">
                                <li className={(selectedProduct === 'Marlboro') ? 'active' : ''} onClick={() => { setSelectedProduct('Marlboro') }}><Link to="#tab1">Marlboro</Link></li>
                                <li className={(selectedProduct === 'Dunhill') ? 'active' : ''}><Link to="#tab2" onClick={() => { setSelectedProduct('Dunhill') }}>Dunhill</Link></li>
                                <li className={(selectedProduct === 'B&H') ? 'active' : ''}><Link to="#tab3" onClick={() => { setSelectedProduct('B&H') }}>B&H</Link></li>
                                <li className={(selectedProduct === 'Black Devil') ? 'active' : ''}><Link to="#tab4" onClick={() => { setSelectedProduct('Black Devil') }}>Black Devil</Link></li>
                                <li className={(selectedProduct === 'Camel') ? 'active' : ''}><Link to="#tab5" onClick={() => { setSelectedProduct('Camel') }}>Camel</Link></li>
                                <li className={(selectedProduct === 'Davidoff') ? 'active' : ''}><Link to="#tab6" onClick={() => { setSelectedProduct('Davidoff') }}>Davidoff</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="tab1" className="tab active">
                                <div className="product-addcart-main">
                                    {Cigarettes && Cigarettes.Cigarettes.map((Cigarettes, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={addCartP1Img} alt=""/>
                                                        <h4>{Cigarettes.title}</h4>
                                                        <p>{Cigarettes.titleTwo}</p>
                                                        <h6><span>{Cigarettes.listed}</span> {Cigarettes.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link
                                                            to="#"
                                                            //href="#" data-toggle="modal" data-target="#exampleModalCenter"
                                                            onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }}
                                                        ><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div id="tab2" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>

                            <div id="tab3" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>

                            <div id="tab4" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>

                            <div id="tab5" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>

                            <div id="tab6" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop = "static"
                showCloseBtn={false}
                isRightSideModal={true}
                child={modalDetail.flag === 'add'
                    ? <Add closeModal={() => handleOnCloseModal()} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    );
}

export default ProductAddToCart;
