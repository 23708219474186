import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { coinStackImg, leftArrowImg, ncigar } from "../../../utilities/images";
import {getNotificationAsync, selectNotificationData} from './notificationSlice'
import { useSelector, useDispatch } from "react-redux";

const Notifications = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(true);
    const notifications = useSelector(selectNotificationData);

    useEffect(()=>{
        dispatch(getNotificationAsync())
    },[dispatch])
    
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={()=>{history.goBack()}}><img src={leftArrowImg} alt=""/></Link> Notifications</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={coinStackImg} alt=""/></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="notification-part">
                    <div className="notification-sec">
                        <h2>Today</h2>
                        {notifications?.today && notifications?.today.map((notification, index) => {
                            return (
                                <div className="notification-item active-notifybox" key={index}>
                                    <img src={ncigar} alt=""/>
                                    <div className="notification-cnt">
                                        <h4>{notification.title} <span>1h ago</span></h4>
                                        <p>{notification.description}</p>
                                    </div>
                                </div>
                            )})
                        }
                            
                    </div>
                    <div className="notification-sec">
                        <h2>Yeasterday</h2>
                        {notifications?.yesterday && notifications?.yesterday.map((notification, index) => {
                            return (
                                <div className="notification-item active-notifybox" key={index}>
                                    <img src={ncigar} alt=""/>
                                    <div className="notification-cnt">
                                        <h4>{notification.title} <span>1h ago</span></h4>
                                        <p>{notification.description}</p>
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                </div>                                      
            </div>
        </>
  );
}  
  
export default Notifications;
  