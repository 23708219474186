import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import identityImg from "../../public/images/identity.png";
import idCardImg from "../../public/images/id-card.png";
import lefArrowImg from "../../public/images/left-arrow.svg";

const AgeVerification = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [document, setDocument] = useState("");
    const [document2, setDocument2] = useState("");
    const [selectedData, setSelectedData] = useState("idcard")

    // console.log(props?.location?.state, "get data")
  
    // const confirmIdentity = location.state ?  location.state.imageSrc : "";

    const handleChange = (e, flag) => {
        const imageFile = e.target.files[0];
        if(flag == 'document_1'){
            setDocument(URL.createObjectURL(imageFile));
        }
        localStorage.setItem('idimage',URL.createObjectURL(imageFile))
    }
    
    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (!document || !props?.location?.state) {
            toast.error("Please select the documents");
            return;
        }
        history.push('/age-verification-check');
    }

    useEffect(() => {
        setDocument((props?.location?.state )? localStorage.getItem('idimage'): idCardImg)
    }, []);

    return (
        <>
            <div className="age-upper">
                <a onClick={() => {history.goBack()}}><img src={lefArrowImg} alt="lefArrowImg" /> Age verification</a>
            </div>

            <div className="age-main">
                <div className="age-cnt">
                    <h2>Proof of Age</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p>
                </div>
                <div className="age-item">
                    <h4>Choose your identity type</h4>
                    {/* <form method="post" action="#"> */}
                        <ul>
                            <li>
                                <div className="custom-radiobtn">
                                    <input type="radio" id="idcard" name="identity" value="idcard" checked={selectedData === 'idcard'} onChange={e => {setSelectedData(e.target.value)}}/>
                                    <label htmlFor="idcard">ID card</label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-radiobtn">
                                    <input type="radio" id="passport" name="identity" value="passport" checked={selectedData === 'passport'} onChange={e => {setSelectedData(e.target.value)}}/>
                                    <label htmlFor="passport">Passport</label>
                                </div>
                            </li>
                            <li>
                                <div className="custom-radiobtn">
                                    <input type="radio" id="drlicence" name="identity" value="driving" checked={selectedData === 'driving'} onChange={e => {setSelectedData(e.target.value)}}/>
                                    <label htmlFor="drlicence">Driving licence</label>
                                </div>
                            </li>
                        </ul>
                        <div className="upload-btn-wrapper upload-btn-wrapper2">
                            <button className="filebtn">
                                <span className="upload-cnt">
                                    <h4>Upload your ID card</h4>
                                    <p>We accept only <span>ID card, passport, driving licence</span></p>
                                </span>
                                <span className="upload-item">
                                    <img src={document ? document : idCardImg } alt="idCardImg" />
                                </span>
                            </button>
                            <input type="file" name="myfile"  onChange={(e) => {handleChange(e, 'document_1')}} />
                        </div>
                        <div className="upload-btn-wrapper upload-btn-wrapper2">
                            <button className="filebtn">
                                <span className="upload-cnt">
                                    <h4>Confirm your identiry</h4>
                                    <p>We accept only <span>ID card, passport, driving licence</span></p>
                                </span>
                                <span className="upload-item" >
                                     <img src={props?.location?.state ? props?.location?.state?.imageSrc : identityImg} alt="identityImg"   onClick={() => {history.push('/AgeVerificationTwo')}} />
                                </span>
                                
                            </button>
                            {/* <input type="file" name="myfile" onChange={(e) => {handleChange(e, 'document_2')}} /> */}
                        </div>
                        {/* </form> */}
                </div>
            </div>

            {document && props?.location?.state &&
                <div className="age-bottom">
                    <a onClick={(e) => {handleOnSubmit(e)}}>Submit</a>
                </div>
            }
        </>
    )

}

export default AgeVerification;