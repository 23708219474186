import React from "react";
import { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg } from "../../../utilities/images";

const CouponsTwo = (props) => {
    const history = useHistory();

    const [couponCode, setCouponCode] = useState("");
    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "couponCode") {
            setCouponCode(value);
        }

    }
    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <h4><Link to="/cart"><img src={leftArrowImg} alt="leftArrowImg" /></Link> Coupons</h4>
            </div>

            <div className="main-content">
                <div className="coupon-part">
                    <form action="#" method="POST">
                        <input type="text" placeholder="Coupon code" onChange={(e) => { handleChange(e, 'couponCode') }} value={couponCode} />
                        <button type="submit" onClick={() => { history.push('/cart') }}>Add Coupon</button>
                    </form>
                </div>

                <div className="coupon-tab">
                    <div className="coupn-ttl">
                        <h2>Select a coupon</h2>
                    </div>
                    <div className="coupon-tab1">
                        <div className="coupon-box">
                            <h2>Welcome coupon <span>USD $2.00</span></h2>
                            <p>jbvrgcu01</p>
                            <h4><span>USD $30 <small>minimum</small></span> <span><small>Valid until</small> Sep 14, 200</span></h4>
                            <div className="line"></div>
                        </div>
                        <div className="coupon-btm">
                            <span>Cigar</span>
                            <Link to="/terms-and-conditions">T&C</Link>
                        </div>
                    </div>

                    <div className="coupon-tab1">
                        <div className="coupon-box coupon-box2">
                            <h2>Not Active Coupon <span>USD $2.00</span></h2>
                            <p>jbvrgcu01</p>
                            <h4><span>USD $30 <small>minimum</small></span> <span><small>Valid until</small> Sep 14, 200</span></h4>
                            <div className="line"></div>
                        </div>
                        <div className="coupon-btm coupon-btm2">
                            <Link to="/terms-&-conditions">T&C</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CouponsTwo;