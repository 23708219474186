import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { leftArrowImg, MenuHeader, PeopleLove1, PeopleLove2, PeopleLove3, PeopleLove4, PeopleLove5 } from '../../../utilities/images'

const HomeRestaurentMenuOption = () => {
    // const history = useHistory()
    const [count, setCount] = useState(0)
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleIncrement = () => {
        setCount(Number(count) + 1)
    }
    const handleDecrement = () => {
        setCount(Number(count) - 1)
    }
    return (
        <>
            <div className="order-heading hmrestmenu-header">
                <h4><Link to='/home-restaurent'><img src={leftArrowImg} alt="" /></Link><span> Chicken Burger <br /> <strong>USD $5.50</strong></span></h4>
                <div className="header-right-item">
                    <img src={MenuHeader} alt="" />
                </div>
            </div>

            <div className="main-content cart-mainpd restmenu-maincont">
                <div className="body-massage-mainprt hmrest-menumainprt">
                    <div className="people-loveprt viewopt-box spice-box">
                        <div className="body-msgtitle">
                            <h2>Spice Level <span>1 Required</span> <i className="fas fa-chevron-up"></i></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="spck1" />
                                        <label htmlFor="spck1"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h6>Extra Spicy</h6>
                                    </div>
                                </div>
                                <div className="spice-right">
                                    <span>Free</span>
                                </div>
                            </div>
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="spck2" />
                                        <label htmlFor="spck2"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h6>Spicy</h6>
                                    </div>
                                </div>
                                <div className="spice-right">
                                    <span>Free</span>
                                </div>
                            </div>
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="spck3" />
                                        <label htmlFor="spck3"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h6>Regular Spicy</h6>
                                    </div>
                                </div>
                                <div className="spice-right">
                                    <span>Free</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="people-loveprt viewopt-box">
                        <div className="body-msgtitle">
                            <h2>Extra topping htmlFor burger <span className="optional">Optional</span></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check6" />
                                        <label htmlFor="check6"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>View options (9)</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="people-loveprt viewopt-box">
                        <div className="body-msgtitle">
                            <h2>Add ons htmlFor burger <span className="optional">Optional</span></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check7" />
                                        <label htmlFor="check7"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>View options (9)</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="people-loveprt">
                        <div className="body-msgtitle">
                            <h2>People love to eat <span className="optional">Optional</span></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check1" />
                                        <label htmlFor="check1"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>Chicken Burger</h4>
                                        <p>USD $5.50</p>
                                    </div>
                                </div>
                                <div className="bodymsg-right">
                                    <img src={PeopleLove1} alt="" />
                                </div>
                            </div>

                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check2" />
                                        <label htmlFor="check2"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>Chicken Burger</h4>
                                        <p>USD $5.50</p>
                                    </div>
                                </div>
                                <div className="bodymsg-right">
                                    <img src={PeopleLove2} alt="" />
                                </div>
                            </div>

                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check3" />
                                        <label htmlFor="check3"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>Chicken Burger</h4>
                                        <p>USD $5.50</p>
                                    </div>
                                </div>
                                <div className="bodymsg-right">
                                    <img src={PeopleLove3} alt="" />
                                </div>
                            </div>

                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check4" />
                                        <label htmlFor="check4"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>Chicken Burger</h4>
                                        <p>USD $5.50</p>
                                    </div>
                                </div>
                                <div className="bodymsg-right">
                                    <img src={PeopleLove4} alt="" />
                                </div>
                            </div>

                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check5" />
                                        <label htmlFor="check5"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>Chicken Burger</h4>
                                        <p>USD $5.50</p>
                                    </div>
                                </div>
                                <div className="bodymsg-right">
                                    <img src={PeopleLove5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-mdlbtm mt-0">
                    <div className="prdmdl-btmleft">
                        <div className="quantity">
                            <form action="#" method="POST">
                                <span className="input-number-decrement" onClick={(count > 0) ? () => handleDecrement() : void (0)}><i className="fas fa-minus"></i></span>
                                <input className="input-number" type="text" value={count} onKeyDown={blockInvalidChar} onChange={(e) => setCount(e.target.value)} min="0" />
                                <span className="input-number-increment" onClick={() => handleIncrement()}><i className="fas fa-plus"></i></span>
                            </form>
                        </div>
                    </div>
                    <div className="prdmdl-btmright">
                        <Link to="#">Add to cart</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeRestaurentMenuOption