import axios from "axios";
import getOffersData from "../../apis/getOffers.json";
import getBrandData from "../../apis/brands.json";
import getProductData from "../../apis/products.json";
import DashboardData from "../../apis/dashboard.json"
import FoodData from "../../apis/foodData.json"
import RetailData from "../../apis/retailData.json"
import FashionStoreData from "../../apis/fashionStore.json"
// A mock function to mimic making an async request for data
export function getAllOffers() {
    return getOffersData
}
export function getAllBrands() {
    return getBrandData
}
export function getAllProducts() {
    return getProductData
}
export function getDashboardData() {
    return DashboardData
}
export function getFoodData() {
    return FoodData
}
export function getRetailData() {
    return RetailData
}
export function getFashionStoreData() {
    return FashionStoreData
}
    //   return new Promise((resolve) =>
    //     axios
    //       .post(`${process.env.REACT_APP_API_STAGING_URL}/register`, formData)
    //       .then(function (response) {
    //         localStorage.setItem("user1", JSON.stringify(response.data));
    //         resolve(response);
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       })
    //   );

        // RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/user/login`)
        //     .then(result => {
        //         let response = result.data;    // Response returned by server
        //         let responseMessage = getApiResponseMessage(response);
        //         let res = getApiResponse(result, responseMessage);
        //         cb(res);
        //     })
        //     .catch(error => {
        //         let res = {
        //             status: false,
        //             message: message.commonError,
        //             type: message.error
        //         };
        //         cb(res);
        //     });