
import { combineReducers } from "redux";
import authReducer from "../../src/app/containers/auth/authSlice"
import dashboardReducer from "../../src/app/containers/dashboard/dashboardSlice"
import browseReducer from "../../src/app/containers/browse/browserSlice"
import productReducer from "../../src/app/containers/productAddToCart/productAddToCartSlice"
import notificationReducer from "../../src/app/containers/notifications/notificationSlice"
import orderReducer from "../../src/app/containers/orders/ordersSlice"

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  auth: authReducer,
  dashboard: dashboardReducer,
  browse: browseReducer,
  product: productReducer,
  notification: notificationReducer,
  order: orderReducer
});

export default rootReducer;