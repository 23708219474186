import React from 'react';
import { Link, useHistory } from 'react-router-dom';
// import { setAuthorizationToken } from "../../../utilities/RestClient";
import {
    Jobr, JobrActive, orderImg,
    orderActiveImg, moreImg, moreActiveImg, Food, FoodActive, RetailActive, Retail
} from "../../../utilities/images";

const Footer = (props) => {
    const history = useHistory();
    const pathName = window.location.pathname;
    // const userLogout = () => {
    //     // props.logout();
    //     // setAuthorizationToken(false);
    //     history.push('/');
    // }
    return (
        <div>
            {/* <footer className="main-footer">
                <strong>Copyright © 2020 <a href="/">Sunless, Inc</a>. </strong>
                All rights reserved. | TOS | Privacy | Cookies
                <div className="float-right">
                    <b className='logout' onClick={() => {userLogout()}}>Log Out</b>
                </div>
            </footer> */}
            <div className="footer-part">
                <div className="footer-item">
                    <Link to="#" onClick={() => { history.push('/dashboard') }} id={(pathName === '/dashboard') || (pathName === '/home-fashion') || (pathName === '/home-fashion-store') || (pathName === '/home-fashion-store-product')
                        ? "active" : ""} >
                        <img className="dark-icon" src={Jobr} alt="homeImg" />
                        <img className="active-icon" src={JobrActive} alt="homeActiveImg" />
                        <span>Jobr</span>
                    </Link>
                </div>
                <div className="footer-item">
                    <Link to="#" onClick={() => { history.push('/home-food') }} id={(pathName === '/home-food') || (pathName === '/restaurent-reservation') ||
                        (pathName === '/home-restaurent') || (pathName === '/home-restaurent-menu') ? "active" : ""}>
                        <img className="dark-icon" src={Food} alt="Food" />
                        <img className="active-icon" src={FoodActive} alt="Foodactiveimage" />
                        <span>Foods</span>
                    </Link>
                </div>
                <div className="footer-item">
                    <Link to="#" onClick={() => { history.push('/home-retail') }} id={(pathName === '/home-retail') || (pathName === '/store')
                        || (pathName === '/browse-next') || (pathName === '/product-add-to-cart') || (pathName === '/product-add-to-cart-two') ? "active" : ""}>
                        <img className="dark-icon" src={Retail} alt="retailsImg" />
                        <img className="active-icon" src={RetailActive} alt="retailsActiveImg" />
                        <span>Retails</span>
                    </Link>
                </div>
                <div className="footer-item">
                    <Link to="#" onClick={() => { history.push('/home-service') }} id={(pathName === '/home-service') ||
                        (pathName === '/home-service-beauty-salon') || (pathName === '/select-service') || (pathName === '/appointment-time')
                        || (pathName === '/book-appointment') ? "active" : ""}>
                        <img className="dark-icon" src={orderImg} alt="orderImg" />
                        <img className="active-icon" src={orderActiveImg} alt="orderActiveImg" />
                        <span>Services</span>
                    </Link>
                </div>
                <div className="footer-item">
                    <Link to="#" onClick={() => { history.push('/more') }} id={(pathName === '/more') ||
                        (pathName === '/settings') || (pathName === '/face-id-and-Pin') || (pathName === '/wallet') ||
                        (pathName === '/payment') || (pathName === '/addresses') ||
                        (pathName === '/coupons') || (pathName === '/favoriteList') ||
                        (pathName === '/product-review-available') || (pathName === '/refer-and-earn') ||
                        (pathName === '/help-center') || (pathName === '/terms-and-conditions') ||
                        (pathName === '/privacy-policy') || (pathName === '/wallet-2') ||
                        (pathName === '/new-address') || (pathName === '/profile') || (pathName === '/edit-profile') ||
                        (pathName === '/help-center-two') || (pathName === '/help-center-three') || (pathName === '/my-account')
                        || (pathName === '/my-account-two') || (pathName === '/home-support') || (pathName === '/report-an-issue') ||
                        (pathName === '/faq') || (pathName === '/faq-verified') || (pathName === '/product-review-available-two') ? "active" : ""}>
                        <img className="dark-icon" src={moreImg} alt="moreImg" />
                        <img className="active-icon" src={moreActiveImg} alt="moreActiveImg" />
                        <span>More</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;