import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { topCategoryOneImg, breadImg, trackImg, beverageOneImg, tobaccoOneImg} from "../../../utilities/images";
import getCategoryData from "../../apis/browse.json";
import { getBrowseAsync, selectBrowseData } from "./browserSlice"
import { useSelector, useDispatch } from "react-redux";

const Browse = (props) => {
    const history = useHistory();
    const [tabView, setTabView] = useState(1);
    const dispatch = useDispatch();
    const allCategories = useSelector(selectBrowseData);

    useEffect(() => {
        dispatch(getBrowseAsync())
    }, [])

    return (
        <>
            <div className="header-searchbar">
                <form action="#" method="POST">
                    <input type="text" placeholder="Search here" />
                </form>
            </div>

            <div className="main-content">
                <div className="container">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm noshadow">
                            <ul className="tab-links text-left pt-4">
                                <li className={(tabView == 1)?'active' : ''} onClick={()=>{setTabView(1)}}><a href="#tab1">Delivery</a></li>
                                <li className={(tabView == 2)?'active' : ''}><a href="#tab2" onClick={()=>{setTabView(2)}}>Pickup</a></li>
                                <li className={(tabView == 3)?'active' : ''}><a href="#tab3" onClick={()=>{setTabView(3)}}>Shipping</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="top-category">
                        <h4>Top Categories</h4>
                        {allCategories && allCategories.top_categories.length > 0 &&
                            <div className="category-part">
                                {allCategories && allCategories.top_categories.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="category-item">
                                                <img src={topCategoryOneImg} alt="topCategoryOneImg"/>
                                                <p>{value.title}</p>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>
                    {allCategories && allCategories.bakery.length > 0 &&
                        <div className="browse-part">
                            <div className="browse-upper">
                                <h2>Bakery</h2>
                                <a href="#">View all</a>
                            </div>
                            {allCategories && allCategories.bakery.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="browse-item">
                                            <div className="browse-left">
                                                <img src={breadImg} alt="breadImg"/>
                                                <span>1</span>
                                                <div className="browse-lftcnt">
                                                    <h4>{value.title}</h4>
                                                    <p>{value.listed}</p>
                                                </div>
                                            </div>
                                            <div className="browse-right text-center">
                                                <a href="#">View</a>
                                                <p><img src={trackImg} alt="trackImg"/> 4 Shops</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )

                            })
                            }
                        </div>
                    }

                    {allCategories && allCategories.beverages.length > 0 &&
                        <div className="browse-part">
                            <div className="browse-upper">
                                <h2>Beverages</h2>
                                <a href="#">View all</a>
                            </div>
                            {allCategories && allCategories.beverages.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="browse-item">
                                            <div className="browse-left">
                                                <img src={beverageOneImg} alt="beverageOneImg"/>
                                                <span>1</span>
                                                <div className="browse-lftcnt">
                                                    <h4>{value.title}</h4>
                                                    <p>{value.listed}</p>
                                                </div>
                                            </div>
                                            <div className="browse-right text-center">
                                                <a href="#">View</a>
                                                <p><img src={trackImg} alt="trackImg"/> 4 Shops</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    }

                    {allCategories && allCategories.tobacco.length > 0 &&
                        <div className="browse-part">
                            <div className="browse-upper">
                                <h2>Tobacco</h2>
                                <a onClick={() => {history.push("./browse-next")}}>View all</a>
                            </div>
                            {allCategories && allCategories.tobacco.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="browse-item">
                                            <div className="browse-left">
                                                <img src={tobaccoOneImg} alt="tobaccoOneImg"/>
                                                <span>1</span>
                                                <div className="browse-lftcnt">
                                                    <h4>{value.title}</h4>
                                                    <p>{value.listed}</p>
                                                </div>
                                            </div>
                                            <div className="browse-right text-center">
                                                <a href="#">View</a>
                                                <p><img src={trackImg} alt="trackImg"/> 4 Shops</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Browse;
