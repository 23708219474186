import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, coinStackImg, bellImg, bagImg, Newhm1, Newhm2, Newhm3, Newhm4, Newhm5, Newhm6, Trslider1,
    Trslider2, Trslider3, Trslider4, Trslider5, Trslider7, Trslider6, offerCrsImg12, starCircleImg
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { getOfferAsync, selectOfferData, selectDashboardData, getDashboardAsync } from "./dashboardSlice"
import { useSelector, useDispatch } from "react-redux";

const Dashboard = (props) => {
    const history = useHistory();
    const offerData = useSelector(selectOfferData);
    const DashboardData = useSelector(selectDashboardData);
    const dispatch = useDispatch();

    const handleClick = (e) => {
        console.log(e, "click event")
        // OwlCarousel.trigger('to.owl.carousel', [this.index(), 1000]);
    }

    useEffect(() => {
        dispatch(getDashboardAsync())
        dispatch(getOfferAsync())
    }, [dispatch])

    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        <Link to="#">
                            <img src={leftArrowImg} alt="leftArrow" />
                        </Link>
                        <Link to="#">Maimi <i className="fas fa-chevron-down"></i></Link>
                        <p>Little Havana, Miami, FL 33130, USA</p>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => { history.push('/notifications') }}><img src={bellImg} alt="bellImg" /></button>
                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                    <Link to="#">0
                        <img src={coinStackImg} alt="coinStack" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="home-tab1prt">
                    <div className="search-barhm">
                        <form action="#" method="POST">
                            <input type="search" placeholder="Search here" onFocus={() => history.push('/search-ints')} />
                        </form>
                    </div>

                    {/* tab section */}
                    <div className="newhome-mainprt">
                        <div className="row">
                            <div className="col-6">
                                <div className="newhome-item newhome-itembox1">
                                    <Link to='/home-food'>
                                        <img src={Newhm1} alt="" />
                                        <h4>Food Delivery</h4>
                                        <p>Best deals on your <br /> favourites</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="newhome-item">
                                    <Link to='/home-retail'>
                                        <img src={Newhm2} alt="" />
                                        <h4>Retail Shops</h4>
                                        <p>Delivery in 30 mins</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="newhome-item">
                                    <Link to="#">
                                        <img src={Newhm3} alt="" />
                                        <h4>Health & Fitness</h4>
                                        <p>Delivery in 30 mins</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="newhome-item newhome-itembox3">
                                    <Link to='/home-fashion'>
                                        <img src={Newhm4} alt="" />
                                        <h4>Fashion</h4>
                                        <p>Best deals on your <br /> favourites</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="newhome-item">
                                    <Link to="#">
                                        <img src={Newhm5} alt="" />
                                        <h4>Classes</h4>
                                        <p>Delivery in 30 mins</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="newhome-item">
                                    <Link to='/home-service'>
                                        <img src={Newhm6} alt="" />
                                        <h4>Services</h4>
                                        <p>Delivery in 30 mins</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Treding food slider */}
                    {DashboardData?.treding_food?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Food</h2>
                            </div>
                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="true"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_food?.map((val, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="carousel-cell dotsClass" key={index}>
                                                    <div className="trslider-main">
                                                        <img src={Trslider1} alt="" />
                                                        <div className="trslider-cnt">
                                                            <h4>{val?.title}</h4>
                                                            <Link to="#">SHOP NOW</Link>
                                                        </div>
                                                    </div>
                                                    <div className="owl-dots dotstab d-block" data-dot="<button>Name</button>">
                                                        {DashboardData?.treding_food?.map((val, index1) => {
                                                            return (
                                                                <button  onClick={(e) => handleClick(e)} className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        : ""}

                    {/* Treding Fashion */}
                    {DashboardData?.treding_fashion?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Fashion</h2>
                            </div>
                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_fashion?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider2} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>{val?.title}</h4>
                                                        <Link to="#">SHOP NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.treding_fashion?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}

                    {/* Treding Retail */}

                    {DashboardData?.treding_fashion?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Retail items</h2>
                            </div>

                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_retail?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider3} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>{val?.title}</h4>
                                                        <Link to="#">SHOP NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.treding_retail?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}

                    {/* Treding HealthCare */}
                    {DashboardData?.treding_healtcare?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Healthcare & Fitness</h2>
                            </div>

                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_healtcare?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider4} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>{val?.title}</h4>
                                                        <Link to="#">SHOP NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.treding_healtcare?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}

                    {/* Treding Services */}

                    {DashboardData?.treding_services?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Services</h2>
                            </div>

                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_services?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider5} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>{val?.title}</h4>
                                                        <Link to="#">SHOP NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.treding_services?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}

                    {/* Treding Online Course */}

                    {DashboardData?.treding_online_courses?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Trending Online Classes</h2>
                            </div>

                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.treding_online_courses?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider6} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>{val?.title}</h4>
                                                        <Link to="#">SHOP NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.treding_online_courses?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}

                    {/* offer */}
                    {offerData?.length > 0 ?
                        <div className="offer-areahm p-0">
                            <div className="offer-title">
                                <h2>Offers</h2>
                            </div>
                            <div className='container-fluid' >
                                <OwlCarousel items={2.5}
                                    className="owl-theme"
                                    nav={false}
                                    dots={false}
                                    loop={true}
                                    autoplay={true}
                                    autoplayTimeout={2000}
                                    margin={0} >
                                    {offerData?.map((offerData, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="carousel-cell w-94">
                                                    <div className="offer-crslpart">
                                                        <div className="offer-crslitem">
                                                            <img src={offerCrsImg12} alt="offerCrsImg1" />
                                                            <div className="offer-crslinner">
                                                                <h2>{offerData.off}</h2>
                                                                <p>Upto {offerData.upto} <span>AD</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </OwlCarousel>
                            </div>
                        </div>
                        : ""}

                    {/* Recently Added */}
                    {DashboardData?.recently_added?.length > 0 ?
                        <div className="banner-slider trslider-part" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Recently Added</h2>
                            </div>

                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={1500}
                                stagePadding={20}
                                slidespeed={100}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    DashboardData?.recently_added?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <div className="trslider-main">
                                                    <img src={Trslider7} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h6>{val?.title} <img src={starCircleImg} alt="" /> <span>{val?.rating}</span></h6>
                                                    </div>
                                                </div>
                                                <div className="owl-dots dotstab d-block">
                                                    {DashboardData?.recently_added?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${DashboardData?.treding_food?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> : ""}
                </div>
            </div>
        </>
    );
};

export default Dashboard