import React from 'react'
import { Link } from 'react-router-dom'

const ReservationTimeModal = (props) => {
    return (
        <div className="modal-content">
            <div className="modal-header modal-headerbok">
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="time-mdlmain">
                    <h2>Please chose a Time below:</h2>

                    <div className="time-mdlprt">
                        <h4>Restaurant </h4>
                        <div className="delivery-time">
                            <span className="inactive">9:00a-10:00a</span>
                            <span>11:00a-12:00p</span>
                            <span>12:00p-1:00p</span>
                            <span className="inactive">1:00p-2:00p</span>
                            <span>2:00p-3:00p</span>
                            <span className="active">3:00p-4:00p</span>
                            <span className="inactive">4:00p-5:00p</span>
                            <span>5:00p-6:00p</span>
                            <span>6:00p-7:00p</span>
                            <span>7:00p-8:00p</span>
                            <span>8:00p-9:00p</span>
                            <span>9:00p-10:00p</span>
                            <span>10:00p-11:00p</span>
                            <span>11:00p-12:00a</span>
                            <span>11:00p-12:00a</span>
                            <span>12:00a-1:00a</span>
                        </div>
                    </div>
                    <div className="time-mdlprt">
                        <h4>Outdoore </h4>
                        <div className="delivery-time">
                            <span className="inactive">9:00a-10:00a</span>
                            <span>11:00a-12:00p</span>
                            <span>12:00p-1:00p</span>
                            <span className="inactive">1:00p-2:00p</span>
                            <span>2:00p-3:00p</span>
                            <span>3:00p-4:00p</span>
                            <span className="inactive">4:00p-5:00p</span>
                            <span>5:00p-6:00p</span>
                            <span>6:00p-7:00p</span>
                            <span>7:00p-8:00p</span>
                            <span>8:00p-9:00p</span>
                            <span>9:00p-10:00p</span>
                            <span>10:00p-11:00p</span>
                            <span>11:00p-12:00a</span>
                            <span>11:00p-12:00a</span>
                            <span>12:00a-1:00a</span>
                        </div>
                    </div>
                    <div className="reserve-btn mt-auto">
                        <Link to="#" onClick={()=> props.details('reservetable')}>Set Time</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReservationTimeModal