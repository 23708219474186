import React from "react";
import { Link } from 'react-router-dom';
import { Info, orderDetails, bxsCoinStackImg, orderTwoSvg, leftArrowImg, starCircleImg } from "../../../utilities/images";

const PastOrderView = (props) => {

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/orders'><img src={leftArrowImg} alt="" /></Link> Order details</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="order-banner"></div>
                <div className="container pastord-mainprt">
                    <div className="ord-profbox">
                        <img src={orderDetails} alt="" />
                        <div className="ord-profcnt">
                            <h4>Miami smoke </h4>
                            <p><img src={starCircleImg} alt="" /> 4.5 (500+ ratings)</p>
                            <span>e-vape store</span>
                        </div>
                    </div>

                    <div className="order-detailsbox">
                        <h4><img src={orderTwoSvg} alt="" /> Order details</h4>
                        <div className="details-ordupr">
                            <ul>
                                <li>Order Number <span>$6.56</span></li>
                                <li>Order From <span>$6.56</span></li>
                                <li>Delivery address <span>$6.56</span></li>
                            </ul>
                        </div>
                        <div className="details-ordmdl">
                            <ul>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="details-mdlleft">
                                        <strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </div>

                        <div className="total-part taxes-fees">
                            <ul>
                                <li>Subtotal <span>$20.56</span></li>
                                <li>Discount <span>-$5.00</span></li>
                                <li> <small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$1.00</span></li>
                                <li>Delivery fees <span>$4.00</span></li>
                                <li><strong>Total</strong> <strong>$19.65</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="helpord-cnt text-center">
                        <Link to="#">Get help with this order</Link>
                    </div>

                    <div className="reorder-btn mt-auto">
                        <Link to='/reorder-view'>Select items to reorder</Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PastOrderView;
