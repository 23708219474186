import React, {useEffect }from "react";
//import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
//import { toast } from 'react-toastify';
import {leftArrowImg,bxsCoinStackImg,referImg,copyImg,coinStackImg,userDoubleImg } from "../../../utilities/images";

const ReferAndEarn = (props) => {

    useEffect(() => {
        //
    }, [])
    
  return (
        <>
          <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Refer & Earn</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="refer-part">
                    <div className="refer-item text-center">
                        <img src={referImg} alt="referImg" />
                    </div>

                    <div className="refer-cnt">
                        <h4>Earn JOBR coins</h4>
                        <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur risus elit, condimentum ut molestie non, imperdiet sit amet magna. Sed interdum interdum ultrices. Nam.</p>
                        <ul>
                            <li><span>shortlink/com <img src={copyImg} alt="copyImg" /></span></li>
                            <li><Link to="#">Share</Link></li>
                        </ul>
                    </div>

                    <div className="refer-box">
                        <Link to='/refer-and-earn-two'>Referral <i className="fas fa-chevron-right"></i></Link>
                        <div className="row">
                            <div className="col-6">
                                <div className="refer-inner">
                                    <span>10 <img src={userDoubleImg} alt="userDoubleImg" /></span>
                                    <p>Referred</p>
                                </div>
                            </div>
                            <div className="col-6 bdr-left">
                                <div className="refer-inner">
                                    <span className="bonust-btn">150 <img src={coinStackImg} alt="coinStackImg" /></span>
                                    <p>Bonus Received</p>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>                          
            </div>
        </>

);
}  
  
  export default ReferAndEarn;