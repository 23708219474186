import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, applePayImg, creditCardImg, debitCardImg, bankFilledImg } from "../../../utilities/images";

const Payment = (props) => {
    const history = useHistory();


    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Payment methods</h4>
            </div>

            <div className="main-content">
                <div className="payment-part">
                    <form action="#" method="POST">
                        <div className="refund-accbox balance-avlp">
                            <h2>Available Balance</h2>
                            <div className="header-right-item">
                                <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                            </div>
                        </div>

                        <ul>
                            <li>
                                <div className="pay-left">
                                    <Link to="#"><img src={applePayImg} alt="" /> Apple pay</Link>
                                </div>
                                <div className="pay-right">
                                    <span>Default</span>
                                    <div className="radio-btn">
                                        <input type="radio" id="radio-btn1" name="radio" checked />
                                        <label htmlFor="radio-btn1"></label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link to="#" onClick={() => { history.push({ pathname: '/credit-card', state: `${props.location.state}` }) }}>
                                    <img src={creditCardImg} alt="creditCardImg" />
                                    Add credit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={debitCardImg} alt="debitCardImg" />
                                    Add debit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={bankFilledImg} alt="bankFilledImg" />
                                    Connect your bank account
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                        </ul>
                    </form>
                    <div className="pay-btn">
                        <Link to="#" onClick={() => { ((props.location.state === "insufficient") ? history.push('/placing-2') : history.push('/placing')) }}>Pay Now</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Payment;