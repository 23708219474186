import React from "react";
import { Link } from 'react-router-dom';
import {bxsCoinStackImg, leftArrowImg } from "../../../utilities/images";

const Setting = (props) => {

  return (
        <>
         <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Settings</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="settings-area">
                    <form action="#" method="POST">
                        <div className="faceid-box settings-part">
                            <h4>Notifications</h4>
                            <ul>
                                <li>
                                    <span>Notification popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                        <label className="custom-control-label" htmlFor="customSwitch1"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Notification popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                        <label className="custom-control-label" htmlFor="customSwitch2"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Notification popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch3" />
                                        <label className="custom-control-label" htmlFor="customSwitch3"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Notification popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch4" />
                                        <label className="custom-control-label" htmlFor="customSwitch4"></label>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="faceid-box settings-part">
                            <h4>Payments</h4>
                            <ul>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                        <label className="custom-control-label" htmlFor="customSwitch5"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch6" />
                                        <label className="custom-control-label" htmlFor="customSwitch6"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch7" />
                                        <label className="custom-control-label" htmlFor="customSwitch7"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch8" />
                                        <label className="custom-control-label" htmlFor="customSwitch8"></label>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="faceid-box settings-part">
                            <h4>Accounts</h4>
                            <ul>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch9" />
                                        <label className="custom-control-label" htmlFor="customSwitch9"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch10" />
                                        <label className="custom-control-label" htmlFor="customSwitch10"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch11" />
                                        <label className="custom-control-label" htmlFor="customSwitch11"></label>
                                    </div>
                                </li>
                                <li>
                                    <span>Payment popup</span>
                                    <div className="custom-control custom-switch custom-switch-sm">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch12" />
                                        <label className="custom-control-label" htmlFor="customSwitch12"></label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </>
     );
    }  
      
   export default Setting;