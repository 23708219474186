import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import {leftArrowImg, bxsCoinStackImg, deliveryTruckImg  } from "../../../utilities/images";

const DeliveryTypes = () => {
    // const history = useHistory()
    const [selectedValue, setSelectedValue] = useState("1h")
  return (
    <div className="main-wrapper">
    <div className="order-heading">
        <div className="header-left-item">
            <h4><Link to='/cart'><img src={leftArrowImg} alt=""/></Link> Delivery</h4>
        </div>
        <div className="header-right-item">
            <Link to="#">0 <img src={bxsCoinStackImg} alt=""/></Link>
        </div>
    </div>

    <div className="main-content">
        <div className="delivery-typearea">
            <form action="#" method="POST">
                <div className="delivery-type-part">
                    <img src={deliveryTruckImg} alt=""/>
                    <div className="delivery-type-cnt">
                        <h2>Express delivery <small>(1 hour delivery window)</small></h2>
                        <h4>$15.00</h4>
                    </div>
                    <div className="delivery-type-radbtn">
                        <span className="radio-btn">
                            <input type="radio" id="radio-btn1" name="radio" checked={selectedValue === "e1h"} onChange={()=>setSelectedValue("e1h")}/>
                            <label htmlFor="radio-btn1"></label>
                        </span>
                    </div>
                </div>
                <div className="delivery-type-part">
                    <img src={deliveryTruckImg} alt=""/>
                    <div className="delivery-type-cnt">
                        <h2>1 hour delivery window</h2>
                        <h4>$10.00</h4>
                    </div>
                    <div className="delivery-type-radbtn">
                        <span className="radio-btn">
                            <input type="radio" id="radio-btn2" name="radio" checked={selectedValue === "1h"} onChange={()=>setSelectedValue("1h")}/>
                            <label htmlFor="radio-btn2"></label>
                        </span>
                    </div>
                </div>

                <div className="delivery-typeablw">
                    <h4>Available 1 hour windows</h4>
                    <div className="delivery-time">
                        <span className="inactive">9:00a-10:00a</span>
                        <span>11:00a-12:00p</span>
                        <span>12:00p-1:00p</span>
                        <span className="inactive">1:00p-2:00p</span>
                        <span>2:00p-3:00p</span>
                        <span className="active">3:00p-4:00p</span>
                        <span>4:00p-5:00p</span>
                        <span>5:00p-6:00p</span>
                        <span>6:00p-7:00p</span>
                        <span>7:00p-8:00p</span>
                        <span>8:00p-9:00p</span>
                        <span>9:00p-10:00p</span>
                        <span>10:00p-11:00p</span>
                        <span>11:00p-12:00a</span>
                        <span>11:00p-12:00a</span>
                        <span>12:00a-1:00a</span>
                    </div>
                    <p>** You can change time slot before 1:00pm EST | Charge applicable</p>
                </div>
                <div className="delivery-type-part">
                    <img src={deliveryTruckImg} alt=""/>
                    <div className="delivery-type-cnt">
                        <h2>2 hour delivery window</h2>
                        <h4>$8.00</h4>
                    </div>
                    <div className="delivery-type-radbtn">
                        <span className="radio-btn">
                            <input type="radio" id="radio-btn3" name="radio" checked={selectedValue === "2h"} onChange={()=>setSelectedValue("2h")}/>
                            <label htmlFor="radio-btn3"></label>
                        </span>
                    </div>
                </div>
            </form>   
            <div className="delivery-typebtn">
                <Link to='/cart'>Done</Link>
            </div>        
        </div>             
    </div>

</div>
  )
}

export default DeliveryTypes