import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { req1Img, tellImg, emailImg, locationImg, inf1Img, inf2Img, supportProfImg, req2Img } from "../../../utilities/images";

const SupportRequestDetails = (props) => {
    const history = useHistory();

    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => { history.push('/my-support-request') }}><i className="fas fa-chevron-left"></i> #XD1256P67</Link></h4>
                <div className="info-reqall">
                    <Link to="#"><i className="fas fa-info-circle"></i></Link>
                    <div className="reqdetails-info">
                        <div className="reqdet-infobox">
                            <div className="reqdetails-upper">
                                <img src={req1Img} alt="req1Img" />
                                <div className="reqdetails-uprcnt">
                                    <h4>Florecilla Jonny</h4>
                                    <p>Me</p>
                                </div>
                            </div>

                            <ul>
                                <li><img src={tellImg} alt="tellImg" /> 4703-515-1193</li>
                                <li><Link to="#"><img src={emailImg} alt="emailImg" /> vidal.webe2@gmail.com</Link></li>
                                <li><img src={locationImg} alt="locationImg" />4318 Daffodil Lane, Savage, <br /> Virginia(VA), 20763</li>
                            </ul>

                            <div className="reqdet-infobtm">
                                <h4>Total Tickets: <strong>16</strong></h4>
                                <ul>
                                    <li>Solved: <strong>9</strong></li>
                                    <li>Cancelled: <strong>1</strong></li>
                                    <li>Open: <strong>1</strong></li>
                                    <li>Unrespond: <strong>1</strong></li>
                                </ul>
                            </div>
                        </div>
                        <div className="reqdet-infobox2">
                            <h4>Assigned agent:</h4>
                            <div className="reqinfo-item">
                                <img src={supportProfImg} alt="supportProfImg" />
                                <div className="reqinfo-cnt2">
                                    <h6>Satomi D.</h6>
                                    <p>13 Jun, 2022  |   12:25p</p>
                                </div>
                            </div>
                            <h4>Also on this ticket:</h4>
                            <ul>
                                <li><img src={inf1Img} alt="inf1Img" />Satomi D.</li>
                                <li><img src={inf2Img} alt="inf2Img" />Satomi D.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="reqdetails-area">
                    <div className="reqdetails-part">
                        <div className="reqdetails-upper">
                            <img src={req1Img} alt="req1Img" />
                            <div className="reqdetails-uprcnt">
                                <h4>Florecilla Jonny</h4>
                                <p>Me</p>
                            </div>
                        </div>
                        <div className="reqdetails-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices suscipit vehicula. Phasellus feugiat, velit nec volutpat pretium, massa metus blandit sem, et auctor est nisi at neque. Phasellus eget velit efficitur.</p>
                            <h6>20 May, 2022   | 08:09am</h6>
                        </div>
                    </div>

                    <div className="reqdetails-part border-0">
                        <div className="reqdetails-upper">
                            <img src={req2Img} alt="req2Img" />
                            <div className="reqdetails-uprcnt">
                                <h4>Satomi D.</h4>
                                <p>JOBR Agent</p>
                            </div>
                        </div>
                        <div className="reqdetails-content" >
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices suscipit vehicula. Phasellus feugiat, velit nec volutpat pretium, massa metus blandit sem, et auctor est nisi at neque. Phasellus eget velit efficitur, posuere erat sit amet, mollis ante. Nunc eleifend nisl commodo facilisis dictum. Sed sodales iaculis euismod. Etiam auctor lectus sed tortor pulvinar aliquam. Etiam in ipsum odio. Duis ut orci gravida mauris semper suscipit.</p>
                            <p>--------------</p>
                            <p>Regards, <br />Satomi D. <br />Jobr.com Support Team</p>
                            <h6>20 May, 2022 | 08:09am</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportRequestDetails;