import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getStore,
} from "./productAddToCartApi";

const initialState = {
    store: null,
    status: "idle",
};

export const getStoreAsync = createAsyncThunk("product/store", async (data) => {
    const response = await getStore();
    return response;
});

export const productSlice = createSlice({
    name: "product",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getStoreAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getStoreAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.store = action.payload;
            })
    },
});

export const selectStoreData = (state) => state.product.store;

export default productSlice.reducer;