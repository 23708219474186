import React from 'react'
import { Link } from 'react-router-dom'
import { leftArrowImg, coinStackImg, orderWhiteImg, phoneLight, Fedex } from "../../../utilities/images"

const AssignedCurrier2 = () => {
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="/tracking"><img src={leftArrowImg} alt="" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={coinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="assinged-main assinged-mainbg2">
                    <div className="assinged-overlay">
                        <div className="assinged-delivery">
                            <div className="delv-astom">
                                <span>Delivery: Tomorrow</span>
                            </div>
                            <div className="assinged-box">
                                <div className="assinged-boxupper">
                                    <div className="assinged-boxuper-left">
                                        <h4>Order status</h4>
                                        <p>In transit</p>
                                    </div>
                                    <div className="assinged-boxuper-right">
                                        <Link to="#"><img src={orderWhiteImg} alt="" /> Order</Link>
                                    </div>
                                </div>

                                <div className="assigned-bottom-part">
                                    <div className="assigned-bottom-box">
                                        <div className="assigned-btmleft">
                                            <img src={Fedex} alt="" />
                                            <div className="assigned-btmcnt">
                                                <h2>FedEx</h2>
                                                <p>9361289677013480485935</p>
                                            </div>
                                        </div>
                                        <div className="assigned-btmright">
                                            <Link to="#"><img src={phoneLight} alt="" /> Contact</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignedCurrier2