import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AddIcon, bagImg, bellImg, Fas9, Fasprd1, Fasprd2, Fasprd3, leftArrowImg, ProductDetails, starCircleImg } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';

const HomeFashionStoreProduct = () => {
    const history = useHistory()
    let megaOffer = [1, 2, 3, 4]
    let mostSell = [1, 2]
    return (
        <>
            <div className="order-heading">
                <h4><Link to='/home-fashion-store'><img src={leftArrowImg} alt="" /></Link> </h4>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                    <button type="button"><img src={bagImg} alt="" /></button>
                </div>
            </div>
            <div className="main-content cart-mainpd fasmain-content">
                <div className="fasproduct-detailsmain">
                    <div className="fasproduct-dtslider">
                        <div className="main-content1">
                            <OwlCarousel items={1}
                                id="owl-csel1"
                                className="owl-carousel owl-theme"
                                nav={false}
                                dots={true}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                                margin={1} >
                                {
                                    megaOffer && megaOffer.map((val, index) => {
                                        return (
                                            <div key={index}>
                                                <img src={ProductDetails} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        <p><img src={starCircleImg} alt="" /> <strong>4.5</strong> (500+ ratings)</p>
                    </div>
                    <div className="fasproduct-dtprt">
                        <div className="fasprd-dtupper">
                            <h2>FORCLAZ By Decathlon</h2>
                            <p>Women Burgundy Waterproof 3 in 1 Travel Trekking Jacket</p>
                            <h4>Price: $10.50</h4>
                        </div>

                        <div className="fasprd-dtcolor">
                            <p>Select color: <strong>Grey</strong></p>
                            <ul>
                                <li className="active"><img src={Fasprd1} alt="" /></li>
                                <li><img src={Fasprd2} alt="" /></li>
                                <li><img src={Fasprd3} alt="" /></li>
                            </ul>
                        </div>

                        <div className="fasprd-size">
                            <p>Select Size: <strong>Grey</strong> </p>
                            <ul>
                                <li>X</li>
                                <li className="active">L</li>
                                <li>XL</li>
                                <li>XXL</li>
                            </ul>
                        </div>

                        <div className="fasprd-cnt">
                            <h4>Shipping charge: <strong>$$$</strong></h4>
                            <p>Estimated arrival within 7 business days with JOBR shipping.</p>
                        </div>

                        <div className="fasprd-btn">
                            <Link to="#"><i className="far fa-heart"></i> Add to Favorit</Link>
                            <Link to="#" className="active"><i className="fas fa-shopping-bag"></i> Add to Bag</Link>
                        </div>
                    </div>
                    <div className="fasprd-dtcontent">
                        <h2>Product Details</h2>
                        <p>A popular type of cigarette from the Marlboro brand due to its smooth flavour and it being mid-priced. </p>
                        <ul>
                            <li>Sleeve Length: <strong>Long Sleeves</strong></li>
                            <li>Type: <strong>Puffer Jacket</strong></li>
                            <li>Collar: <strong>Hooded </strong></li>
                            <li>Lining Fabric: <strong>Polyester </strong></li>
                            <li>Print or Pattern Type: <strong>Solid </strong></li>
                            <li>Occasion: <strong>Casual </strong></li>
                        </ul>
                    </div>
                    <div className="fasprd-likeprt">
                        <h2>You may also like</h2>
                        {
                            (mostSell?.length > 0) ?
                                <div className="fashion-mainprt">
                                    <div className="row">
                                        {mostSell?.map((val, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="col-6">
                                                        <div className="fashion-item">
                                                            <div className="fashion-slider" id="bluedotSlider">
                                                                <OwlCarousel items={1}
                                                                    className="owl-theme"
                                                                    nav={false}
                                                                    loop={true}
                                                                    dots={true}
                                                                    autoplay={true}
                                                                    autoplayTimeout={2000}
                                                                    margin={1} >
                                                                    {
                                                                        mostSell && mostSell?.map((val, index) => {
                                                                            return (
                                                                                <div className="carousel-cell" key={index}>
                                                                                    <img src={Fas9} alt="" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </OwlCarousel>
                                                            </div>
                                                            <div className="fashion-cnt">
                                                                <div className="fashion-color">
                                                                    <span className="red active"></span>
                                                                    <span className="info"></span>
                                                                    <span className="black"></span>
                                                                    <span className="dark"></span>
                                                                    <span className="white"></span>
                                                                </div>
                                                                <p><strong>Made well</strong> colored cozy short cardigan</p>
                                                                <ul>
                                                                    <li>X</li>
                                                                    <li className="active">L</li>
                                                                    <li>XL</li>
                                                                    <li>XXL</li>
                                                                </ul>
                                                                <h4>$5.65</h4>
                                                                <Link to="#">
                                                                    <img src={AddIcon} alt="" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeFashionStoreProduct