import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loginApi,
  registerSendOtp
} from "./authApi";

const initialState = {
  user: null,
  otp: null,
  status: "idle",
};

export const loginAsync = createAsyncThunk("auth/login", async (data) => {
  const response = await loginApi(data);
  return response.data;
});
export const registerOtpSendAsync = createAsyncThunk("auth/registerotp", async (data) => {
  const response = await registerSendOtp(data);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(registerOtpSendAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerOtpSendAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.otp = action.payload;
      })

  },
});

export const { logout } = authSlice.actions;

export const selectLoginAuth = (state) => state.auth.user;

export default authSlice.reducer;