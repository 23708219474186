import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import {
    leftCircleImg, searchCircleImg, dotCircleImg, storeShopImg, discountOutlineImg, topCategoryOneImg,
    sldVap3Img, sldCigar3Img, starCircleImg, sldCigar6Img, deliveryParcelImg, sldRolling3Img, bannerSld2Img, arrowRightSideImg, ProductSt8, More1
} from "../../../utilities/images";
import getStore from "../../apis/store.json"
import OwlCarousel from 'react-owl-carousel';

const Store = (props) => {
    const history = useHistory();

    const [allStoreItem, setAllStoreItem] = useState("");
    useEffect(() => {
        setAllStoreItem(getStore)
    }, [])
    return (
        <>
            <div className="main-content">
                <div className="store-bannerbg">
                    <div className="left-circle">
                        <Link to="#" onClick={() => { history.goBack() }}>
                            <img src={leftCircleImg} alt="leftCircleImg" />
                        </Link>
                    </div>
                    <div className="store-iconrit">
                        <Link to="#"><img src={searchCircleImg} alt="searchCircleImg" /></Link>
                        <Link to="#"><img src={dotCircleImg} alt="dotCircleImg" /></Link>
                    </div>
                </div>
                <div className="store-banner-part">
                    <div className="store-box">
                        <div className="store-item">
                            <img src={storeShopImg} alt="storeShopImg" />
                        </div>

                        <div className="store-cnt">
                            <h4>Miami smoke <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="starCircleImg" /> 4.5 (500+ ratings)</p>
                            <h6>e-vape store</h6>
                            <ul>
                                <li>Outlate <span>Little Havana</span></li>
                                <li>30 mins <Link to="#">Delivery to Miami <i className="fas fa-chevron-down"></i></Link></li>
                            </ul>
                            <h3><Link to="#"><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</Link></h3>
                        </div>
                    </div>
                    <div className="store-btn">
                        <Link to="#"><img src={discountOutlineImg} alt="discountOutlineImg" />
                            50% off upto $10</Link>
                    </div>
                </div>

                <div className="store-category">
                    <div className="store-categpart store-product-mainprt">
                        {allStoreItem && allStoreItem?.top_categories?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="store-catgitem">
                                        <Link to="#">
                                            <img src={topCategoryOneImg} alt="topCategoryOneImg" />
                                        </Link>
                                        <p>{item?.title}</p>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                        <div className="store-catgitem">
                            <Link to='/browse-next'>
                                <img src={ProductSt8} alt="" />
                            </Link>
                            <p>More</p>
                        </div>
                    </div>
                </div>

                <div className="store-slider">
                    {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="store-sldhead">
                                <h2>Cigar</h2>
                                <Link to='/product-add-to-cart'>View all</Link>
                            </div>

                            <OwlCarousel items={2.5}
                                className="owl-theme main-carousel2"
                                nav={false}
                                dots={false}
                                margin={1}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                            >
                                {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell" key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={sldCigar3Img} alt="" />
                                                        <h4>{item.title}</h4>
                                                        <p>{item.titleTwo}</p>
                                                        <h6><span>{item.listed}</span>{item.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </OwlCarousel>
                        </>
                    }

                    {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="store-sldhead">
                                <h2>Cigarettes</h2>
                                <Link to='/product-add-to-cart'>View all</Link>
                            </div>
                            <OwlCarousel items={2.5}
                                className="owl-theme main-carousel2"
                                nav={false}
                                dots={false}
                                margin={1}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                            >
                                {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell" key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={sldCigar6Img} alt="" />
                                                        <h4>{item.title}</h4>
                                                        <p>{item.titleTwo}</p>
                                                        <h6><span>{item.listed}</span>{item.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </OwlCarousel>
                        </>
                    }

                    {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="store-sldhead">
                                <h2>Vape</h2>
                                <Link to='/product-add-to-cart'>View all</Link>
                            </div>
                            <OwlCarousel items={2.5}
                                className="owl-theme main-carousel2"
                                nav={false}
                                dots={false}
                                margin={1}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                            >
                                {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell" key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={sldVap3Img} alt="" />
                                                        <h4>{item.title}</h4>
                                                        <p>{item.titleTwo}</p>
                                                        <h6><span>{item.listed}</span>{item.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </OwlCarousel>
                        </>}

                    {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="store-sldhead">
                                <h2>Rolling papers</h2>
                                <Link to='/product-add-to-cart'>View all</Link>
                            </div>

                            <OwlCarousel items={2.5}
                                className="owl-theme main-carousel2"
                                nav={false}
                                dots={false}
                                margin={1}
                                loop={true}
                                autoplay={true}
                                autoplayTimeout={2000}
                            >
                                {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell" key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={sldRolling3Img} alt="" />
                                                        <h4>{item.title}</h4>
                                                        <p>{item.titleTwo}</p>
                                                        <h6><span>{item.listed}</span>{item.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </OwlCarousel>
                        </>}

                    {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="storesld-bannerbtm">
                                <OwlCarousel items={1.1}
                                    className="owl-theme main-carousel3"
                                    nav={false}
                                    dots={false}
                                    margin={0}
                                    loop={true}
                                    autoplay={true}
                                    autoplayTimeout={2000}
                                >
                                    {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="carousel-cell">
                                                    <div className="banner2-item">
                                                        <img src={bannerSld2Img} alt="bannerSld2Img" />
                                                        <Link to='/cart'>Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </OwlCarousel>
                            </div>
                        </>}
                </div>
                <div className="belllIcon">
                    <img src={More1} alt="more" />
                </div>
            </div>
        </>
    );
}

export default Store;