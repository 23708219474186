import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ClockBlue, dotCircleImg, emailImg, FoodGallery1, FoodGallery2, FoodGallery3, FoodGallery4, FoodGallery5, FoodGallery6, HomeFood, LeftCircle2, Plater, searchCircleImg, starCircleImg, tellImg } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal'
import ReserveTableModal from './ReserveTableModal'
import ReserveTimeModal from './ReservationTimeModal'
import ReserveDateModal from './ReservationDateModal'

const HomeFoodReservation = () => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const handleModalDetails = (data) => {
        // console.log(data,"data")
        if(data === 'time' || data === 'reservetable' || data === "date"){
            setModalDetail({ show: true, flag: data }); setKey(Math.random());
        }
    }

    return (
        <div className="main-content">
            <div className="store-bannerbg homefood-bannerbg">
                <div className="left-circle">
                    <Link to="#" onClick={() => history.push('/home-food')}>
                        <img src={LeftCircle2} alt="" />
                    </Link>
                </div>
                <div className="store-iconrit">
                    <Link to="#"><img src={searchCircleImg} alt="" /></Link>
                    <Link to="#"><img src={dotCircleImg} alt="" /></Link>
                </div>
            </div>
            <div className="store-banner-part noshadow">
                <div className="store-box store-boxhmfd">
                    <div className="store-item">
                        <img src={HomeFood} alt="" />
                    </div>

                    <div className="store-cnt hmserv-cnt">
                        <h4>Quality Bistro <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                        <p><img src={starCircleImg} alt="" /> 4.7 (1360 ratings)</p>
                        <h6>Restaurant</h6>
                        <ul>
                            <li><img src={emailImg} alt="" /> Flowers@gmail.com</li>
                            <li><img src={tellImg} alt="" /> 612717070</li>
                            <li><img src={ClockBlue} alt="" /> OPEN till 12.00 AM</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="hmfood-maincnt">
                <p>Quality Bistro, a bustling, steak-centric brasserie in midtown Manhattan, offers contemporary takes on bistro classNameics in a whimsical space. Quality Bistro has the best French Onion Soup and Steak Frites in New York City. Located in Midtown Manhattan near Central Park, MOMA, Carnegie <Link to="#">...more</Link></p>

                <div className="hmfood-gallery">
                    <div className="hmfood-glitem">
                        <img src={FoodGallery1} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery2} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery3} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery4} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery5} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery6} alt="" />
                    </div>
                </div>

                <div className="hmfood-btn">
                    <Link className="menu" to="#"><img src={Plater} alt=""/> Menu</Link>
                    <Link to="#" className="book-tble" onClick={() => { setModalDetail({ show: true, flag: "reservetable" }); setKey(Math.random()); }}>Book table</Link>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                size="md"
                child={modalDetail.flag === 'reservetable' ?
                    <ReserveTableModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)}/>
                    : (modalDetail.flag === 'time')?
                    <ReserveTimeModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)}/>
                    : (modalDetail.flag === 'date')?<ReserveDateModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)}/>
                    :""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default HomeFoodReservation