import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import { leftArrowImg } from "../../../utilities/images";
import Verify2 from "../../public/images/veirfy-2.png";
import PinField from "react-pin-field";

const SetNewPin = (props) => {
    const pathName = window.location.pathname;
    const history = useHistory();
    const [goBackUrl, setGoBackUrl]  = useState("");
    const [step, setStep]  = useState(1);
    const [randomNumber, setRandomNumber]  = useState(Math.random());
    const [modalKey, setModalKey]  = useState(Math.random());
    const [pin, setPin]  = useState("");
    const [reneteredPin, setRenteredPin]  = useState("");
    const [modalDetail, setModalDetail] = useState({
        show: false
    });

    const onComplete = (code, flag) =>{
        if(flag == 'step_1'){
            setPin(code);
            setStep(2);
            setRandomNumber(Math.random());
        }

        if(flag == 'step_2'){
            setRenteredPin(code);

            if(props?.location?.state == "setuppin"){
                history.push('/face-id-and-Pin')
            }
            if(!props?.location?.state){
                setTimeout(() => {
                    setModalDetail({show: true});
                    setModalKey(Math.random());
                }, 1000);
            }
        }
    }

    const handleOnCloseModal = () => {
        setModalDetail({show: false});
        setModalKey(Math.random());
    }

    useEffect(() => {
        if(pathName == '/reset-new-pin'){
            setGoBackUrl('/otp-verification');
        }
    }, []);

    return (
        <>
            <div className="age-upper pin-upper">
                <a onClick={() => {history.goBack()}}><img src={leftArrowImg} alt="leftArrowImg" /> Set up PIN</a>
                <a className="cancel-btn" onClick={() => {history.push("/")}}>Cancel</a>
            </div>
            <div className="pin-main">
                {step == 1 ? 
                    <>
                        <p>Enter your PIN</p>
                        <div className="pin-box text-center" >
                            <PinField  
                                key={randomNumber}
                                className='input_digits_'
                                length={4}
                                validate={/^[0-9]$/} 
                                format={key => key} 
                                // onChange ={() => {}} 
                                onComplete={(code) => onComplete(code, 'step_1')} 
                            />
                        </div>
                    </>
                :
                    <>
                        <p>Reenter your PIN</p>
                        <div className="pin-box text-center" >
                            <PinField  
                                key={randomNumber}
                                className='input_digits_'
                                length={4}
                                validate={/^[0-9]$/} 
                                format={key => key} 
                                // onChange ={() => {}} 
                                onComplete={(code) => onComplete(code, 'step_2')} 
                            />
                        </div>
                    </>
                }  
            </div>

            <CustomModal
                key = {modalKey}
                show = {modalDetail.show}
                backdrop = "static"
                title = {modalDetail.title}
                child = {
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Done!</h4>
                            <img src={Verify2} alt=""/>
                            <p>You are verified!</p>
                            <h6>Your new pin is set now</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn">
                            <a onClick={() => history.push('/')} className="active">Log in</a>
                        </div>
                    </div>
                }
                // size="lg"
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />

            <div className="bottom-bar">
                <span></span>
            </div>
        </>
  );
}  

 
  export default SetNewPin;