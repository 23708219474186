import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import circleImg from "../../public/images/circle-btn.png";
import Webcam from "react-webcam";

//const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};


const AgeVerificationTwo = (props) => {
    const history = useHistory();
    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);

    console.log("image",image);

    const capture = React.useCallback(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          console.log("imageSrc",imageSrc);
          setImage(imageSrc)
          history.push({pathname: '/age-verification', state: {imageSrc: imageSrc }});
        },
        [webcamRef]
      );
    
    
    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <main className="main-wrapper age-verification-wraper2">
                <div className="scan-part">
                    {/* <button type="button"></button> */}
                    <Webcam
                        audio={false}
                        height={"200px"}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={"100%"}
                        videoConstraints={videoConstraints}
                    />
                </div>

                <div className="circle-btn">
                    <a onClick={(e) => {capture()}}>
                        <img src={circleImg} alt="circleImg"  />            
                    </a>
                </div>  
            </main>
        
        </>
    )

}

export default AgeVerificationTwo;


