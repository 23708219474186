import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../../public/images/logo.svg";
import objectImg from "../../../public/images/objects.png";

const Step2 = (props) => {
    const history = useHistory();

    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState({
        userName: "",
        firstName: "",
        lastName: "",
        birth: "",
        pin: "",
        confirmPin: ""
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({...formData, [name]: value });
        setErrorMessages({...errorMessages, [name]: "sdf" });
    }
    
    const handleOnSubmit = () => {
        if(!formData.userName){
            toast.error("Please enter your user name");
            return false;
        }
        else if(formData.userName.length < 3){
            toast.error("Username should be of 3 digits");
            return false;
        }
        else if(!formData.firstName){
            toast.error("Please enter your first name");
            return false;
        }
        else if(formData.firstName.length < 3){
            toast.error("First Name should be of 3 digits");
            return false;
        }
        else if(!formData.birth){
            toast.error("Please select your date of birth");
            return false;
        }
        else if(!formData.pin){
            toast.error("Please enter PIN");
            return false;
        }
        else if(formData.pin.length < 4){
            toast.error("PIN should be of 4 digits");
            return false;
        }
        else if(!formData.confirmPin){
            toast.error("Please confirm your pin");
            return false;
        }
        else if(formData.pin != formData.confirmPin){
            toast.error("PIN and confirm PIN should be same");
            return false;
        }
        history.push('/dashboard');
    }

    useEffect(() => {
        // props.logout();
    }, []);

    return (
        <>
            
            <div className="logo-part">
                <a onClick={() => {history.push('/')}}>
                    <img  src={logo} alt="" />
                </a>
            </div>

            <div className="register-main">
                <div className="container">
                    <div className="register-item">
                        <form method="POST" >
                            <label htmlFor="r1">Choose a username</label>
                            <input className="user-name" type="text" id="r1" value={formData.userName} placeholder="User name" name="userName" onChange={(e) => { handleChange(e, 'userName') }} />
                            <label htmlFor="rf1">First name</label>
                            <input className="user-name" type="text" id="rf1" value={formData.firstName} placeholder="First name" name="firstName" onChange={(e) => { handleChange(e, 'firstName') }} />
                            <label htmlFor="rf2">Last name</label>
                            <input className="user-name" type="text" id="rf2" value={formData.lastName} placeholder="Last name"  name="lastName" onChange={(e) => { handleChange(e, 'lastName') }}/>
                            <label htmlFor="r2">Date of birth</label>
                            <input className="birth" type="date" id="r2" value={formData.birth} placeholder="mm / dd / yyyy" name="birth" onChange={(e) => { handleChange(e, 'birth') }} />
                            <label htmlFor="r3">Choose a PIN</label>
                            <input className="pin" type="password" id="r3" value={formData.pin} maxLength="4" placeholder="0- 0- 0- 0"  name="pin" onChange={(e) => { handleChange(e, 'pin') }}/>
                            <span className="mb-4">Must be 4 digit </span>
                            <label htmlFor="r4">Confirm PIN</label>
                            <input className="pin" type="password" id="r4" value={formData.confirmPin} maxLength="4" placeholder="0- 0- 0- 0"  name="confirmPin" onChange={(e) => { handleChange(e, 'confirmPin') }}/>
                        </form>
                    </div>
                </div>
            </div>

            <div className="bottom-part bottom-prt2 reg-bottom">
                <img src={objectImg} alt="objectImg" />
                <a className="bottom-btn" onClick={() => {handleOnSubmit()}}>Next</a>
            </div>
    
        </>
    )

}

export default Step2;