import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getOrders,
} from "./ordersApi";

const initialState = {
    orders: null,
    status: 'idle',
};

export const getOrderAsync = createAsyncThunk("orders/order", async (data) => {
    const response = await getOrders();
    return response;
});

export const ordersSlice = createSlice({
    name: "order",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getOrderAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOrderAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.orders = action.payload;
            })
    },
});

export const selectOrderData = (state) => state.order.orders;

export default ordersSlice.reducer;