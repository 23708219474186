import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-phone-input-2/lib/style.css';
import 'react-calendar/dist/Calendar.css';
import './fonts/Maison-Mono_Medium.ttf';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();