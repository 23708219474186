import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { bellImg, SearchImg, coinStackImg, ProfileImg, locationImg, Iconp1, Iconp2, Iconp3, Iconp4, Iconp5, heart, products, Iconp6, Iconp7, Iconp8, Iconp9, Iconp10, UserVerified } from "../../../utilities/images";

const More = (props) => {
    const history = useHistory();
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4>More</h4>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="bell" /></button>
                    <button type="button">
                        <img src={SearchImg} alt="search" />
                    </button>
                    <Link to="#">0 <img src={coinStackImg} alt="coinstack" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="more-profile-area">
                    <div className="more-profilebox">
                        <Link to='/profile'>
                            <div className="profile-itemmr">
                                <img src={ProfileImg} alt="profileimg" />
                            </div>
                            <div className="profile-cntmr">
                                <h4>Farraj Muhanna Shadid</h4>
                                <p><img src={locationImg} alt="location" /> 4554 Adamsville Road, Mission, TX 78572</p>
                                <h6><img src={UserVerified} alt="" /> ID: 548962134</h6>
                            </div>
                            <div className="profile-moreicon">
                                <i className="fas fa-chevron-right"></i>
                            </div>
                        </Link>
                    </div>

                    <div className="profile-links">
                        <ul>
                            <li><Link to='/age-verification'><img src={Iconp1} alt="iconp1" /> Age verifications <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/face-id-and-Pin'><img src={Iconp2} alt="iconp2" />Face ID & PIN <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/wallet'><img src={Iconp3} alt="iconp3" />JBR Wallet <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/payment'><img src={Iconp3} alt="iconp3" />Payment methods <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/addresses'><img src={Iconp4} alt="iconp4" />Addresses <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/coupons'><img src={Iconp5} alt="iconp5" />Coupons <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/favoriteList'><img src={heart} alt="heart" />Favorite list <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/product-review-available'><img src={products} alt="products" /> Product review<i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/refer-and-earn'><img src={Iconp6} alt="iconp6" />Refer & Earn <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/help-center'><img src={Iconp7} alt="iconp7" />Help center <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/settings'><img src={Iconp8} alt="iconp8" />Settings <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/terms-and-conditions'><img src={Iconp9} alt="iconp9" />Terms & Conditions <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/privacy-policy'><img src={Iconp10} alt="iconp10" />Privacy Policy <i className="fas fa-chevron-right"></i></Link></li>
                        </ul>
                    </div>
                    <div className="profile-btmprt">
                        <Link to='/'>Logout</Link>
                        <p>V 0000.0001</p>
                    </div>
                </div>
            </div>
        </>
    );
}


export default More;
