import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { bxsCoinStackImg, leftArrowImg, mapViewImg } from "../../../utilities/images";

const TrackingTwo = (props) => {
    const history = useHistory();

    useEffect(() => {
        // 
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push('/tracking') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="assinged-main">
                    <div className="assinged-overlay">
                        <div className="assinged-box">
                            <div className="assinged-boxupper">
                                <div className="assinged-boxuper-left">
                                    <h4>Order status</h4>
                                    <p>Preparing your order</p>
                                </div>
                                <div className="assinged-boxuper-right">
                                    <Link to="#" onClick={()=>history.push('/tracking')}><img src={mapViewImg} alt="mapViewImg" /> Map</Link>
                                </div>
                            </div>

                            <div className="track-timeline">
                                <ul>
                                    <li>
                                        <h4>Verify code</h4>
                                        <p>---- ----</p>
                                    </li>
                                    <li>
                                        <h4>Delivery</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li>
                                        <h4>Next to your block</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li>
                                        <h4>Product Pickup</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li>
                                        <h4>Assign Driver</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li>
                                        <h4>Ready to pickup</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>Order accepted</h4>
                                        <p>21 Jun, 2022  |   10:30 am</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrackingTwo;