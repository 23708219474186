import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { CalenderDark2, ClockDark2, MinusCircle, Person, PlusCircle, UserDark2 } from '../../../utilities/images'

const ReserveTableModal = (props) => {
    const [count, setCount] = useState(0)
    return (
        <div className="modal-content">
            <div className="modal-header modal-headerbok">
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="coinplan-modal">
                    <div className="booktbl-modalcnt appoint-timemain">
                        <h2>Book A Table</h2>
                        <p>When do you want to book table?</p>
                        <ul>
                            <li onClick={()=>{props.details('date')}}>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={CalenderDark2} alt="" /> Reservation </h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>Today, Sep 3, 2022 <i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                            <li onClick={()=>{props.details('time')}}>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={ClockDark2} alt="" />Time</h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>7:00 PM <i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <div className="appont-tmleft">
                                    <h4><img src={UserDark2} alt="" /> How many people?</h4>
                                </div>
                            </li>
                        </ul>
                        <div className="people-number">
                            <div className="quantity">
                                <form action="#" method="POST">
                                    <span className="input-number-decrement" onClick={()=>setCount(Number(count)-1)}>
                                        <img src={MinusCircle} alt="" />
                                    </span>
                                    <input className="input-number" type="text" value={count} onChange={(e)=>setCount(e.target.value)}/>
                                    <span className="input-number-increment" onClick={()=>setCount(Number(count)+1)}>
                                        <img src={PlusCircle} alt="" />
                                    </span>
                                </form>
                            </div>
                        </div>

                        <div className="person-item">
                            <img src={Person} alt="" />
                        </div>

                        <div className="reserve-btn">
                            <Link to="#" onClick={props.close}>Reserve</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReserveTableModal