import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    starCircleImg, clockImg, trackImg,
    arrowRightSideImg, sendImg, perfume2Img,
    deliveryTruckImg, dotDarkImg, deliveryParcelImg,
    shoeDisplay2Img, shoeBannerImg,
    banner3Img, offer5Img, zaraImg, shoeOneImg
} from "../../../utilities/images";
import { getBrandAsync, selectBrandData, getOfferAsync, selectOfferData, getProductAsync, selectProductData } from "./dashboardSlice"
import { useSelector, useDispatch } from "react-redux";
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";

const DashboardShipping = () => {
    const dispatch = useDispatch();
    const brandData = useSelector(selectBrandData);
    const offerData = useSelector(selectOfferData);
    const productData = useSelector(selectProductData);

    let shippingSlider = [1, 2, 3, 4]

    useEffect(() => {
        dispatch(getBrandAsync())
        dispatch(getOfferAsync())
        dispatch(getProductAsync())
    }, [dispatch])
    return (
        <>
            <div className="home-tab3prt">
                <div className="search-barhm">
                    <form action="#" method="POST">
                        <input type="search" placeholder="Search here" />
                    </form>
                </div>

                <div id="bannerSlider">
                    <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={1500}
                        slidespeed={100}
                        dotsdata="false"
                    >
                        {
                            shippingSlider && shippingSlider.map((val, index) => {
                                return (
                                    <div className="carousel-cell" key={index}>
                                        <div>
                                            <img src={banner3Img} alt="banner3Img" />
                                        </div>
                                        <div className="owl-dots dotstab d-block">
                                            {shippingSlider?.map((val, index1) => {
                                                return (
                                                    <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${shippingSlider?.length}` : ""}</span></button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

                <div className="offer-areahm p-0">
                    <div className="offer-title">
                        <h2>Offers</h2>
                    </div>
                    <OwlCarousel items={2}
                        className="owl-theme"
                        nav={false}
                        dots={false}
                        loop={true}
                        autoplay={true}
                        autoplayTimeout={2000}
                        margin={10} >
                        {
                            offerData && offerData.map((val, index) => {
                                return (
                                    <div key={index}>
                                        <div className="offer-crslpart">
                                            <div className="offer-crslitem">
                                                <img src={offer5Img} alt="offer5Img" />
                                                <div className="offer-crslinner">
                                                    <h2>{val.off}</h2>
                                                    <p>{val.upto}<span>{val.ad ? 'AD' : ''}</span></p>
                                                </div>
                                            </div>
                                            <div className="offer-crslcnt">
                                                <h4>{val.title}</h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" /> {val.rating}</li>
                                                    <li><img src={clockImg} alt="clockImg" />{val.time}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </div>


            <div className="topbrand-area pt-25">
                <div className="browse-part brand-tprt">
                    <div className="browse-upper">
                        <h2>Top Cloths Brands</h2>
                        <Link to="#">View all</Link>
                    </div>

                    {brandData?.top_cloth?.length > 0 ? brandData.top_cloth.map((value, index) => {
                        let itemsLength = brandData.top_cloth.length;
                        return (
                            <React.Fragment key={index}>
                                <div className={itemsLength === (index + 1) ? 'browse-item border-0' : 'browse-item'} key={index}>
                                    <div className="browse-left">
                                        <img src={zaraImg} alt="zaraImg" />
                                        <span>{value.id}</span>
                                        <div className="browse-lftcnt">
                                            <h4>{value.title}</h4>
                                            <p>{value.listed}</p>
                                        </div>
                                    </div>
                                    <div className="browse-right text-center">
                                        <Link to="#">View</Link>
                                        <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }) : ""
                    }
                </div>

                <div className="browse-part brand-tprt">
                    <div className="browse-upper">
                        <h2>Top Perfumes Brands</h2>
                        <Link to="#">View all</Link>
                    </div>

                    {brandData?.top_perfume?.length > 0 ? brandData.top_perfume.map((value, index) => {
                        let itemsLength = brandData.top_perfume.length;
                        return (
                            <React.Fragment key={index}>
                                <div className={itemsLength === (index + 1) ? 'browse-item border-0' : 'browse-item'} key={index}>
                                    <div className="browse-left">
                                        <img src={perfume2Img} alt="zaraImg" />
                                        <span>{value.id}</span>
                                        <div className="browse-lftcnt">
                                            <h4>{value.title}</h4>
                                            <p>{value.listed}</p>
                                        </div>
                                    </div>
                                    <div className="browse-right text-center">
                                        <Link to="#">View</Link>
                                        <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }) : ""
                    }
                </div>

                <div className="browse-part brand-tprt">
                    <div className="browse-upper">
                        <h2>Top Shoes Brands</h2>
                        <Link to="#">View all</Link>
                    </div>

                    {brandData?.top_shoe?.length > 0 ? brandData.top_shoe.map((value, index) => {
                        let itemsLength = brandData.top_shoe.length;
                        return (
                            <React.Fragment key={index}>
                                <div className={itemsLength === (index + 1) ? 'browse-item border-0' : 'browse-item'} key={index}>
                                    <div className="browse-left">
                                        <img src={shoeOneImg} alt="zaraImg" />
                                        <span>{value.id}</span>
                                        <div className="browse-lftcnt">
                                            <h4>{value.title}</h4>
                                            <p>{value.listed}</p>
                                        </div>
                                    </div>
                                    <div className="browse-right text-center">
                                        <Link to="#">View</Link>
                                        <p><img src={trackImg} alt="trackImg" /> 4 Shops</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }) : ""
                    }
                </div>
            </div>
            <div className="banner-slider">
                <div className="main-content3">
                    <div id="owl-csel3" className="owl-carousel owl-theme">
                        <div>
                            <div className="banner2-item">
                                <img src={shoeBannerImg} alt="shoeBannerImg" />
                                <Link to="/cart">Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                            </div>
                        </div>
                        <div>
                            <div className="banner2-item">
                                <img src={shoeBannerImg} alt="shoeBannerImg" />
                                <Link to="/cart">Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                            </div>
                        </div>
                        <div>
                            <div className="banner2-item">
                                <img src={shoeBannerImg} alt="shoeBannerImg" />
                                <Link to="/cart">Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {productData.length > 0 ?
                <div className="delivery-parthm">
                    <div className="delivery-upperhm">
                        <ul>
                            <li><Link to="#"><img src={sendImg} alt="sendImg" /> Near Me</Link></li>
                            <li><Link to="#"><img src={deliveryTruckImg} alt="deliveryTruckImg" /> Free Delivery</Link></li>
                            <li><Link to="#"><img src={dotDarkImg} alt="dotDarkImg" /></Link></li>
                        </ul>
                    </div>

                    {productData && productData.map((value, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="delivery-sechm">
                                    <img className="delv-prd" src={shoeDisplay2Img} alt="delv1Img" />
                                    <h4>{value.title}</h4>
                                    <ul>
                                        <li><img src={starCircleImg} alt="starCircleImg" />{value.rating}</li>
                                        <li><img src={clockImg} alt="clockImg" />{value.time}</li>
                                        <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value.upto}</li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        )
                    })
                    }
                </div>
                : ""}
        </>
    )
}

export default DashboardShipping