import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lock2 from "../../public/images/lock-2.png";
import PinField from "react-pin-field"

const OtpVerification = (props) => {
    const history = useHistory();
    const pathName = window.location.pathname;
    const [otp, setOtp]  = useState("");
    const [loading, setLoading] = useState(false);

    const onComplete = (code) =>{
        setOtp(code);
    }

    const handleOnSubmit = () => {
        if(!otp){
            toast.error("Please enter OTP");
            return;
        }
        else if(otp.length < 5){
            toast.error("Please enter a valid OTP");
            return;
        }

        if(pathName == '/registartion/otp-verification'){
            history.push('/registartion/step-2');
        } 
        else {
            history.push('/reset-new-pin');
        }
    }
    
    useEffect(() => {
        // 
    }, []);

    return (
        <>  
        
            <div className="lock-part2">
                <img src={Lock2} alt=""/>
            </div>

            <div className="verify-part">
                <h2>Verify your <strong>phone number</strong></h2>
                <p>Enter OTP code here</p>
                <div className="verify-box text-center">
                    <PinField  className='input_digits_'
                        length={5}
                        validate={/^[0-9]$/} 
                        format={key => key} 
                        // onChange ={() => {}} 
                        onComplete={(code) => onComplete(code)} 
                    />
                </div>
            </div>

            <div className="bottom-part">
                <p>Didn’t receive Code? <a onClick={(e) => {return e.preventDefault()}}>Resend</a></p>
                <a className="bottom-btn" onClick={() => {handleOnSubmit()}}>Continue</a>
            </div>
        </>
    )

}

export default OtpVerification;